<template>
  <div>
    <h1>Modul-Zuweisung</h1>
    <ol>
      <li>Jahrgang auswählen</li>
      <li>BiVo auswählen</li>
      <li>Module auswählen</li>
      <li>Mit Klick auf Button zuweisen</li>
    </ol>
    <!-- Zuweisung durchführen -->
    <form
      class="
        row
        align-items-lg-end
        pt-2
        pb-lg-3
        sticky-md-top
        bg-light
        shadow-sm
        mb-2
        mx-lg-0
      "
      @submit.prevent="createModulBiVo()"
    >
      <div class="mb-2 col-12 col-sm mb-lg-0 col-xl-auto">
        <label for="jahrgang">Jahrgang</label>
        <date-picker
          v-model="auswahl_jahrgang"
          type="year"
          input-class="form-control"
          :clearable="true"
          :input-attr="{ id: 'jahrgang', required: true }"
          value-type="YYYY"
        ></date-picker>
      </div>
      <div
        class="mb-2 col-12 col-sm mb-lg-0 col-xl-2"
        :class="{ 'is-invalid': errorBiVo && !auswahl_bivo }"
      >
        <label>BiVo</label>
        <multi-select
          :options="bivo"
          label="fldBiVo"
          trackBy="pkBiVo"
          v-model="auswahl_bivo"
          :searchable="false"
          :show-labels="false"
          :placeholder="errorBiVo ? errorBiVo : ''"
        ></multi-select>
      </div>
      <div class="mb-3 col-12 col-lg-auto mb-lg-0 d-flex flex-column">
        <span class="fw-bold">{{ auswahl_module.length }} ausgewählt</span>
        <button
          type="submit"
          class="btn btn-primary w-100"
          :disabled="!auswahl_module.length"
        >
          Module diesem Jahrgang und dieser BiVo zuweisen
        </button>
      </div>
      <div class="mb-2 col-12 col-sm-auto ms-sm-auto mb-lg-0"></div>
    </form>
    <!-- Tabelle -->
    <div class="table-responsive">
      <table class="table table-hover text-nowrap align-middle">
        <thead>
          <tr>
            <th>
              <input
                class="form-check-input"
                type="checkbox"
                :checked="allChecked"
                @change="checkAll($event)"
              />
            </th>
            <th>üK</th>
            <th>Modul-Nr.</th>
            <th>Bezeichnung</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="modul in module">
            <tr
              :key="modul.pkModul"
              @click="toggleModul(modul)"
              style="cursor: pointer"
            >
              <td>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="auswahl_module"
                  :value="modul"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="modul.fldÜK"
                  @click.stop
                  :disabled="
                    !auswahl_module.find(
                      (a_modul) => a_modul.pkModul == modul.pkModul
                    )
                  "
                />
              </td>
              <td>{{ modul.fldModulNr }}</td>
              <td>{{ modul.fldModulname }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// Node-Package-Referenz: https://www.npmjs.com/package/vue2-datepicker
import DatePicker from "vue2-datepicker";
// Node-Package-Referenz: https://vue-multiselect.js.org/
import MultiSelect from "vue-multiselect";
import MultiSelectCSS from "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: {
    "date-picker": DatePicker,
    "multi-select": MultiSelect,
  },
  data() {
    return {
      module: [],
      bivo: [],
      auswahl_module: [],
      auswahl_jahrgang: "",
      auswahl_bivo: "",
      errorBiVo: false,
    };
  },
  created() {
    axios.get("/api/module").then((response) => {
      let module = response.data;
      for (let i = 0; i < module.length; i++) {
        const modul = module[i];
        Vue.set(modul, "fldÜK", false);
      }
      this.module = module;
    });
    axios.get("/api/bivo").then((response) => {
      this.bivo = response.data;
    });
  },
  methods: {
    createModulBiVo() {
      let sendable = true;
      if (!this.auswahl_bivo) {
        sendable = false;
        this.errorBiVo = "Wählen Sie eine BiVo aus";
      } else {
        this.errorBiVo = false;
      }
      if (
        sendable &&
        confirm("Wollen Sie diese Zuweisung wirklich durchführen?")
      ) {
        let data = {
          module: this.auswahl_module,
          jahrgang: this.auswahl_jahrgang,
          bivo: this.auswahl_bivo,
        };
        axios.post("/api/modulbivo", data);
        setTimeout(() => {
          alert("Die ausgewählten Module wurden erfolgreich zugewiesen");
          this.auswahl_module = [];
          this.auswahl_jahrgang = "";
          this.auswahl_bivo = "";
          this.module.forEach((modul) => {
            modul.fldÜK = false;
          });
        }, 500);
      }
    },
    toggleModul(modul) {
      if (this.auswahl_module.includes(modul)) {
        this.auswahl_module.splice(this.auswahl_module.indexOf(modul), 1);
        modul.fldÜK = false;
      } else {
        this.auswahl_module.push(modul);
      }
    },
    checkAll(event) {
      for (let i = 0; i < this.module.length; i++) {
        const modul = this.module[i];
        let found = false;
        if (event.target.checked) {
          for (let i = 0; i < this.auswahl_module.length; i++) {
            const checkedModul = this.auswahl_module[i];
            if (checkedModul.pkModul == modul.pkModul) {
              found = true;
              break;
            }
          }
          if (found == false) {
            this.auswahl_module.push(modul);
          }
        } else {
          for (let i = 0; i < this.auswahl_module.length; i++) {
            const checkedModul = this.auswahl_module[i];
            if (checkedModul.pkModul == modul.pkModul) {
              this.auswahl_module.splice(i, 1);
            }
          }
        }
      }
    },
  },
  computed: {
    allChecked() {
      if (!this.module.length) {
        return false;
      }
      let allFound = true;
      for (let i = 0; i < this.module.length; i++) {
        const modul = this.module[i];
        if (
          !this.auswahl_module.some(
            (checkedModul) => checkedModul.pkModul == modul.pkModul
          )
        ) {
          allFound = false;
        }
      }
      if (allFound) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>