<template>
  <div>
    <h1>Noten-Versand</h1>
    <!-- Filter -->
    <form
      class="
        row
        align-items-lg-center
        pt-2
        pb-3
        sticky-lg-top
        bg-light
        shadow-sm
        mb-2
        mx-lg-0
      "
      @submit.prevent="filter()"
    >
      <div class="col row align-items-lg-end gx-3 pe-0 ps-3">
        <div class="col-12 mb-2 col-lg mb-lg-0 col-xl-auto">
          <label for="nachnameFilter">Nachname</label>
          <input
            id="nachnameFilter"
            type="text"
            class="form-control"
            v-model="aktiverFilterNachname"
          />
        </div>
        <div class="col-12 mb-2 col-lg mb-lg-0 col-xl-auto">
          <label for="vornameFilter">Vorname</label>
          <input
            id="vornameFilter"
            type="text"
            class="form-control"
            v-model="aktiverFilterVorname"
          />
        </div>
        <div class="col-12 mb-2 col-lg mb-lg-0 col-xl-auto">
          <label for="lehrbetriebFilter">Firma</label>
          <input
            id="lehrbetriebFilter"
            type="text"
            class="form-control"
            v-model="aktiverFilterLehrbetrieb"
          />
        </div>
        <div class="col-12 mb-2 col-lg-auto mb-lg-0">
          <label for="BiVoFilter">BiVo</label>
          <select
            class="form-select"
            id="BiVoFilter"
            v-model="aktiverFilterBiVo"
          >
            <option></option>
            <template v-for="filter in BiVoFilter">
              <option :key="filter" :value="filter">{{ filter }}</option>
            </template>
          </select>
        </div>
        <div class="col-12 mb-2 col-lg-auto mb-lg-0">
          <label for="lehrbeginnFilter">Lehrbeginn</label>
          <select
            class="form-select"
            id="lehrbeginnFilter"
            v-model="aktiverFilterLehrbeginn"
          >
            <option></option>
            <template v-for="filter in lehrbeginnFilter">
              <option :key="filter" :value="filter">{{ filter }}</option>
            </template>
          </select>
        </div>
        <div class="col-12 mb-2 col-lg-auto mb-lg-0">
          <button
            type="submit"
            class="
              btn btn-primary
              icon
              d-inline-flex
              align-items-center
              gap-1
              w-100
              justify-content-center
            "
          >
            <i class="bi bi-funnel"></i>Filtern
          </button>
        </div>
        <div
          class="col-12 mb-2 text-md-end mt-md-2 mb-md-0 col-lg-auto mt-lg-0"
          v-if="lernende.length !== gefilterteLernende.length"
        >
          <button class="btn btn-link" @click="resetFilter()">
            Filter zurücksetzen
          </button>
        </div>
      </div>
      <div class="d-flex flex-column mt-2 col-xxl-auto mt-xxl-0 ms-xxl-auto">
        <span class="fw-bold">{{ ausgewählteLernende.length }} ausgewählt</span>
        <div
          class="
            d-flex
            flex-column flex-sm-row flex-xxl-column flex-xxxl-row
            gap-2
          "
        >
          <button
            class="btn btn-primary col col-md-auto"
            :disabled="!ausgewählteLernende.length"
            @click="sendNoten(ausgewählteLernende, false)"
          >
            an Lernende und Betreuer senden
          </button>
          <button
            class="btn btn-success col col-md-auto"
            :disabled="!ausgewählteLernende.length"
            @click="sendNoten(ausgewählteLernende, true)"
          >
            an mich ({{ current_benutzer_email }}) senden
          </button>
        </div>
      </div>
    </form>
    <div
      v-if="lernende.length && !gefilterteLernende.length"
      class="alert alert-danger text-center mt-3"
    >
      Es wurden keine Ergebnisse gefunden.
    </div>
    <!-- Tabelle -->
    <div class="table-responsive mb-3" v-if="gefilterteLernende.length">
      <table class="table table-hover text-nowrap align-middle">
        <thead>
          <tr>
            <th>
              <input
                class="form-check-input"
                type="checkbox"
                :checked="allChecked"
                @change="checkAll($event)"
              />
            </th>
            <th>Lehrbeginn</th>
            <th>Nachname</th>
            <th>Vorname</th>
            <th>E-Mail-Adresse</th>
            <th>Firma</th>
            <th>Betreuer Nachname</th>
            <th>Betreuer Vorname</th>
            <th>Betreuer E-Mail-Adresse</th>
            <th>BiVo</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="lernender in gefilterteLernende">
            <tr
              :key="lernender.pkLernender"
              @click="toggleLernender(lernender)"
              style="cursor: pointer"
            >
              <td>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="ausgewählteLernende"
                  :value="lernender"
                />
              </td>
              <td>{{ lernender.fldLehrbeginn }}</td>
              <td>{{ lernender.fldNachnameL }}</td>
              <td>{{ lernender.fldVornameL }}</td>
              <td>{{ lernender.fldeMailL }}</td>
              <td>
                {{ lernender.fldLehrbetrieb }}
              </td>
              <td>
                {{ lernender.fldNachnameB }}
                <template v-if="!lernender.fldNachnameB">
                  <strong class="badge bg-danger">n.v.</strong>
                </template>
              </td>
              <td>
                {{ lernender.fldVornameB }}
              </td>
              <td>{{ lernender.fldeMailB }}</td>
              <td>{{ lernender.fldBiVo }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current_benutzer_email: "",
  },
  data() {
    return {
      lernende: [],
      module: [],
      modulnoten: [],
      gefilterteLernende: [],
      ausgewählteLernende: [],
      lehrbeginnFilter: [],
      BiVoFilter: [],
      aktiverFilterVorname: "",
      aktiverFilterNachname: "",
      aktiverFilterLehrbetrieb: "",
      aktiverFilterBiVo: "",
      aktiverFilterLehrbeginn: "",
    };
  },
  created() {
    axios.get("/api/lernende").then((response) => {
      this.lernende = response.data;
      for (let i = 0; i < this.lernende.length; i++) {
        const lernender = this.lernende[i];
        if (!this.lehrbeginnFilter.includes(lernender.fldLehrbeginn)) {
          this.lehrbeginnFilter.push(lernender.fldLehrbeginn);
        }
        if (!this.BiVoFilter.includes(lernender.fldBiVo)) {
          this.BiVoFilter.push(lernender.fldBiVo);
        }
        this.lehrbeginnFilter.sort((a, b) => b - a);
        this.BiVoFilter.sort();
      }
      this.filter();
    });
  },
  methods: {
    filter() {
      let gefilterteLernende = this.lernende;
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldVornameL
          .toLowerCase()
          .includes(this.aktiverFilterVorname.toLowerCase());
      });
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldNachnameL
          .toLowerCase()
          .includes(this.aktiverFilterNachname.toLowerCase());
      });
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldLehrbetrieb
          .toLowerCase()
          .includes(this.aktiverFilterLehrbetrieb.toLowerCase());
      });
      if (this.aktiverFilterLehrbeginn !== "") {
        gefilterteLernende = gefilterteLernende.filter((lernender) => {
          return lernender.fldLehrbeginn == this.aktiverFilterLehrbeginn;
        });
      }
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldBiVo.includes(this.aktiverFilterBiVo);
      });
      this.gefilterteLernende = gefilterteLernende;
    },
    resetFilter() {
      this.aktiverFilterVorname = "";
      this.aktiverFilterNachname = "";
      this.aktiverFilterLehrbetrieb = "";
      this.aktiverFilterBiVo = "";
      this.aktiverFilterLehrbeginn = "";
      this.filter();
    },
    checkAll(event) {
      for (let i = 0; i < this.gefilterteLernende.length; i++) {
        const lernender = this.gefilterteLernende[i];
        let found = false;
        if (event.target.checked) {
          for (let i = 0; i < this.ausgewählteLernende.length; i++) {
            const checkedLernender = this.ausgewählteLernende[i];
            if (checkedLernender.pkLernender == lernender.pkLernender) {
              found = true;
              break;
            }
          }
          if (found == false) {
            this.ausgewählteLernende.push(lernender);
          }
        } else {
          for (let i = 0; i < this.ausgewählteLernende.length; i++) {
            const checkedLernender = this.ausgewählteLernende[i];
            if (checkedLernender.pkLernender == lernender.pkLernender) {
              this.ausgewählteLernende.splice(i, 1);
            }
          }
        }
      }
    },
    toggleLernender(lernender) {
      if (this.ausgewählteLernende.includes(lernender)) {
        this.ausgewählteLernende.splice(
          this.ausgewählteLernende.indexOf(lernender),
          1
        );
      } else {
        this.ausgewählteLernende.push(lernender);
      }
    },
    sendNoten(lernende, sendToUser) {
      let url = "/api/sendNoten/";
      sendToUser ? (url += "true") : !sendToUser ? (url += "false") : null;
      let gesendeteLernende = [];
      for (let i = 0; i < lernende.length; i++) {
        const lernender = lernende[i];
        let ausgabeLernender =
          lernender.fldNachnameL + ", " + lernender.fldVornameL;
        !sendToUser && lernender.fldeMailB
          ? (ausgabeLernender +=
              " - " + lernender.fldNachnameB + ", " + lernender.fldVornameB)
          : null;
        gesendeteLernende.push(ausgabeLernender);
      }
      let messageText = "";
      if (sendToUser) {
        messageText =
          "Möchten Sie die Notenportfolios folgender Lernenden an sich selbst senden?:\n";
      } else {
        messageText =
          "Möchten Sie die entsprechenden Notenportfolios an folgende Lernende und Betreuer senden?:\n";
      }
      if (confirm(messageText + gesendeteLernende.join("\n"))) {
        axios.post(url, lernende);
        setTimeout(() => {
          alert("Die Notenportfolios wurden erfolgreich gesendet");
          this.ausgewählteLernende = [];
        }, 500);
      }
    },
  },
  computed: {
    allChecked() {
      if (!this.gefilterteLernende.length) {
        return false;
      }
      let allFound = true;
      for (let i = 0; i < this.gefilterteLernende.length; i++) {
        const lernender = this.gefilterteLernende[i];
        if (
          !this.ausgewählteLernende.some(
            (checkedLernender) =>
              checkedLernender.pkLernender == lernender.pkLernender
          )
        ) {
          allFound = false;
        }
      }
      if (allFound) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>