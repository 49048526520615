<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1>Lernende</h1>
      <template v-if="current_benutzer_rolle == 'Administrator'">
        <button
          @click="papierkorb(true)"
          class="btn btn-danger icon d-inline-flex align-items-center gap-1"
          v-if="!showPapierkorb"
          title="Papierkorb anzeigen"
        >
          <i class="bi bi-trash3"></i>Papierkorb
        </button>
        <button
          @click="papierkorb(false)"
          class="btn btn-danger icon d-inline-flex align-items-center gap-1"
          v-if="showPapierkorb"
        >
          <i class="bi bi-box-arrow-left"></i>Papierkorb verlassen
        </button>
      </template>
    </div>
    <!-- Filter -->
    <form
      class="row align-items-lg-end pt-2 pb-lg-3 sticky-lg-top bg-light shadow-sm mb-2 mx-lg-0"
      @submit.prevent="filter()"
    >
      <div class="col-12 mb-2 col-lg col-xxl-auto mb-lg-0">
        <label for="nachnameFilter">Nachname</label>
        <input
          id="nachnameFilter"
          type="text"
          class="form-control"
          v-model="aktiverFilterNachname"
        />
      </div>
      <div class="col-12 mb-2 col-lg col-xxl-auto mb-lg-0">
        <label for="vornameFilter">Vorname</label>
        <input
          id="vornameFilter"
          type="text"
          class="form-control"
          v-model="aktiverFilterVorname"
        />
      </div>
      <div class="col-12 mb-2 col-lg col-xxl-auto mb-lg-0">
        <label for="lehrbetriebFilter">Firma</label>
        <input
          id="lehrbetriebFilter"
          type="text"
          class="form-control"
          v-model="aktiverFilterLehrbetrieb"
        />
      </div>
      <div class="col-12 mb-2 col-lg col-xxl-auto mb-lg-0">
        <label for="betreuerFilter">Betreuer</label>
        <input
          id="betreuerFilter"
          type="text"
          class="form-control"
          v-model="aktiverFilterBetreuer"
        />
      </div>
      <div class="col-12 mb-2 col-lg-auto mb-lg-0">
        <label for="BiVoFilter">BiVo</label>
        <select class="form-select" id="BiVoFilter" v-model="aktiverFilterBiVo">
          <option></option>
          <template v-for="filter in BiVoFilter">
            <option :key="filter" :value="filter">{{ filter }}</option>
          </template>
        </select>
      </div>
      <div class="col-12 mb-2 col-lg-auto mb-lg-0">
        <label for="lehrbeginnFilter">Lehrbeginn</label>
        <select
          class="form-select"
          id="lehrbeginnFilter"
          v-model="aktiverFilterLehrbeginn"
        >
          <option></option>
          <template v-for="filter in lehrbeginnFilter">
            <option :key="filter" :value="filter">{{ filter }}</option>
          </template>
        </select>
      </div>
      <div class="col-12 mb-2 col-lg-auto mb-lg-0">
        <button
          type="submit"
          class="btn btn-primary icon d-inline-flex align-items-center gap-1 w-100 justify-content-center"
        >
          <i class="bi bi-funnel"></i>Filtern
        </button>
      </div>
      <div
        class="col-12 mb-2 text-lg-end mt-lg-2 mb-lg-0 col-xxl-auto mt-xxl-0"
        v-if="
          (lernende.length !== gefilterteLernende.length && !showPapierkorb) ||
          (deletedLernende.length !== gefilterteLernende.length &&
            showPapierkorb)
        "
      >
        <button class="btn btn-link" @click="resetFilter()">
          Filter zurücksetzen
        </button>
      </div>
    </form>
    <form id="createLernender" @submit.prevent="createLernender()"></form>
    <div
      v-if="lernende.length && !gefilterteLernende.length"
      class="alert alert-danger text-center mt-3"
    >
      Es wurden keine Ergebnisse gefunden.
    </div>
    <!-- Tabelle -->
    <div
      class="table-responsive"
      v-if="gefilterteLernende.length || !lernende.length"
    >
      <table class="table text-nowrap align-middle">
        <thead>
          <tr>
            <th>
              <button
                class="btn btn-primary icon"
                @click="showForm = true"
                v-if="!showPapierkorb"
                title="neuen Lernenden erfassen"
              >
                <i class="bi bi-plus"></i>
              </button>
            </th>
            <th>Nachname</th>
            <th>Vorname</th>
            <th>E-Mail-Adresse</th>
            <th>BiVo</th>
            <th>Lehrbeginn</th>
            <th>Firma</th>
            <th>Betreuer Nachname</th>
            <th>Betreuer Vorname</th>
            <th>Betreuer E-Mail-Adresse</th>
            <th v-if="showPapierkorb"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showForm">
            <td></td>
            <td>
              <input
                form="createLernender"
                class="form-control"
                type="text"
                v-model="newLernender.fldNachnameL"
                required
              />
            </td>
            <td>
              <input
                form="createLernender"
                class="form-control"
                type="text"
                v-model="newLernender.fldVornameL"
                required
              />
            </td>
            <td>
              <input
                form="createLernender"
                class="form-control"
                type="email"
                v-model="newLernender.fldeMailL"
                required
              />
            </td>
            <td>
              <select
                form="createLernender"
                class="form-select"
                v-model="newLernender.fkBiVo"
                required
              >
                <option></option>
                <template v-for="bivo in bivo">
                  <option :key="bivo.pkBiVo" :value="bivo.pkBiVo">
                    {{ bivo.fldBiVo }}
                  </option>
                </template>
              </select>
            </td>
            <td>
              <date-picker
                v-model="newLernender.fldLehrbeginn"
                type="year"
                input-class="form-control"
                :clearable="true"
                :input-attr="{ form: 'createLernender', required: true }"
                value-type="YYYY"
              ></date-picker>
            </td>
            <td>
              <select
                form="createLernender"
                class="form-select"
                v-model="newLernender.fkLehrbetrieb"
                required
              >
                <option></option>
                <template v-for="lehrbetrieb in lehrbetriebe">
                  <option
                    :key="lehrbetrieb.pkLehrbetrieb"
                    :value="lehrbetrieb.pkLehrbetrieb"
                  >
                    {{ lehrbetrieb.fldLehrbetrieb }}
                  </option>
                </template>
              </select>
            </td>
            <td colspan="2">
              <select
                form="createLernender"
                class="form-select"
                v-model="newLernender.fkBetreuer"
                :disabled="newLernender.fkLehrbetrieb === ''"
                required
              >
                <option></option>
                <template v-for="betreuer in betreuer">
                  <option
                    :key="betreuer.pkBetreuer"
                    :value="betreuer.pkBetreuer"
                    v-if="betreuer.fkLehrbetrieb == newLernender.fkLehrbetrieb"
                  >
                    {{ betreuer.fldNachnameB }}, {{ betreuer.fldVornameB }}
                  </option>
                </template>
              </select>
            </td>
            <td>
              <button
                type="submit"
                form="createLernender"
                class="btn btn-success icon"
                title="Speichern"
                ref="submitButton"
              >
                <i class="bi bi-check"></i>
              </button>
              <button
                @click="
                  showForm = false;
                  {
                    for (const key in newLernender) {
                      newLernender[key] = '';
                    }
                  }
                "
                class="btn btn-danger icon"
                title="Abbrechen"
              >
                <i class="bi bi-x"></i>
              </button>
            </td>
          </tr>
          <template v-for="(lernender, index) in gefilterteLernende">
            <tr :key="lernender.pkLernender">
              <td>
                <button
                  class="btn btn-primary icon"
                  v-if="!lernender.editable && !showPapierkorb"
                  @click="lernender.editable = true"
                  title="Bearbeiten"
                >
                  <i class="bi bi-pencil"></i>
                </button>
                <button
                  class="btn btn-success wiederherstellen"
                  @click.once="restoreLernender(lernender)"
                  v-if="showPapierkorb"
                  title="Wiederherstellen"
                >
                  <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 14 16"
                    style="enable-background: new 0 0 14 16; padding: 3px 0"
                    xml:space="preserve"
                    height="34.5px"
                    width="25px"
                    fill="white"
                  >
                    <g>
                      <path
                        class="st0"
                        d="M7,6.3c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1c0-0.4,0.1-0.9,0.3-1.3
		c0.1-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.3,0-0.4,0.1c-0.8,1.8,0,4,1.8,4.9c1.8,0.8,4,0,4.9-1.8c0.8-1.8,0-4-1.8-4.9C8,5.8,7.5,5.7,7,5.7
		V6.3z"
                        style="
                          fill-rule: evenodd;
                          clip-rule: evenodd;
                          fill: #ffffff;
                          stroke: #ffffff;
                          stroke-width: 0.5;
                          stroke-miterlimit: 10;
                        "
                      />
                      <path
                        class="st1"
                        d="M7,7.2V4.8c0-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.1,0L5.3,5.9C5.2,6,5.2,6.1,5.3,6.1c0,0,0,0,0,0l1.4,1.2
		C6.8,7.4,6.9,7.4,7,7.2C7,7.3,7,7.3,7,7.2z"
                      />
                    </g>
                    <path
                      class="st1"
                      d="M5.5,1h3C8.8,1,9,1.2,9,1.5v1H5v-1C5,1.2,5.2,1,5.5,1z M10,2.5v-1C10,0.7,9.3,0,8.5,0h-3C4.7,0,4,0.7,4,1.5v1
	H1.5l0,0h-1C0.2,2.5,0,2.7,0,3s0.2,0.5,0.5,0.5H1l0.9,10.7c0.1,1,1,1.8,2,1.8h6.2c1,0,1.9-0.8,2-1.8L13,3.5h0.5
	C13.8,3.5,14,3.3,14,3s-0.2-0.5-0.5-0.5h-1l0,0H10z M12,3.5l-0.8,10.6c0,0.5-0.5,0.9-1,0.9H3.9c-0.5,0-1-0.4-1-0.9L2,3.5H12z"
                      style="fill: #ffffff"
                    />
                  </svg>
                </button>
                <template v-if="lernender.editable">
                  <form
                    :id="'updateLernender' + index"
                    @submit.prevent="updateLernender(lernender)"
                  >
                    <button
                      type="submit"
                      class="btn btn-success icon"
                      title="Speichern"
                    >
                      <i class="bi bi-check"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger icon"
                      @click="
                        lernender.editable = false;
                        lernender.showDelete = false;
                        {
                          for (const key in lernender.edit) {
                            lernender.edit[key] = String(lernender[key]);
                          }
                        }
                      "
                      title="Abbrechen"
                    >
                      <i class="bi bi-x"></i>
                    </button>
                  </form>
                </template>
              </td>
              <td>
                <template v-if="!lernender.editable">
                  {{ lernender.fldNachnameL }}
                </template>
                <input
                  type="text"
                  :form="'updateLernender' + index"
                  class="form-control"
                  v-model="lernender.edit.fldNachnameL"
                  v-if="lernender.editable"
                  required
                />
              </td>
              <td>
                <template v-if="!lernender.editable">
                  {{ lernender.fldVornameL }}
                </template>
                <input
                  type="text"
                  :form="'updateLernender' + index"
                  class="form-control"
                  v-model="lernender.edit.fldVornameL"
                  v-if="lernender.editable"
                  required
                />
              </td>
              <td>
                <template v-if="!lernender.editable">
                  {{ lernender.fldeMailL }}
                </template>
                <input
                  type="email"
                  :form="'updateLernender' + index"
                  class="form-control"
                  v-model="lernender.edit.fldeMailL"
                  v-if="lernender.editable"
                  required
                />
              </td>
              <td>
                <template v-if="!lernender.editable">
                  {{ lernender.fldBiVo }}
                </template>
                <select
                  :form="'updateLernender' + index"
                  class="form-select"
                  v-model="lernender.edit.fkBiVo"
                  v-if="lernender.editable"
                  required
                >
                  <option></option>
                  <template v-for="bivo in bivo">
                    <option :key="bivo.pkBiVo" :value="bivo.pkBiVo">
                      {{ bivo.fldBiVo }}
                    </option>
                  </template>
                </select>
              </td>
              <td>
                <template v-if="!lernender.editable">
                  {{ lernender.fldLehrbeginn }}
                </template>
                <date-picker
                  v-if="lernender.editable"
                  v-model="lernender.edit.fldLehrbeginn"
                  type="year"
                  input-class="form-control"
                  :clearable="false"
                  :input-attr="{
                    form: 'updateLernender' + index,
                    required: true,
                  }"
                  value-type="YYYY"
                ></date-picker>
              </td>
              <td>
                <template v-if="!lernender.editable">
                  {{ lernender.fldLehrbetrieb }}
                </template>
                <select
                  :form="'updateLernender' + index"
                  class="form-select"
                  v-model="lernender.edit.fkLehrbetrieb"
                  v-if="lernender.editable"
                  required
                >
                  <option></option>
                  <template v-for="lehrbetrieb in lehrbetriebe">
                    <option
                      :key="lehrbetrieb.pkLehrbetrieb"
                      :value="lehrbetrieb.pkLehrbetrieb"
                    >
                      {{ lehrbetrieb.fldLehrbetrieb }}
                    </option>
                  </template>
                </select>
              </td>
              <td :colspan="lernender.editable ? '2' : '1'">
                <template v-if="!lernender.editable">
                  {{ lernender.fldNachnameB }}
                  <template v-if="!lernender.fldNachnameB">
                    <strong class="badge bg-danger">n.v.</strong>
                  </template>
                </template>
                <select
                  :form="'updateLernender' + index"
                  class="form-select"
                  v-model="lernender.edit.fkBetreuer"
                  v-if="lernender.editable"
                  :disabled="
                    !lernender.edit.fkLehrbetrieb ||
                    lernender.edit.fkLehrbetrieb === 0
                  "
                  required
                >
                  <option></option>
                  <template v-for="betreuer in betreuer">
                    <option
                      :key="betreuer.pkBetreuer"
                      :value="betreuer.pkBetreuer"
                      v-if="
                        lernender.edit.fkLehrbetrieb == betreuer.fkLehrbetrieb
                      "
                    >
                      {{ betreuer.fldNachnameB }}, {{ betreuer.fldVornameB }}
                    </option>
                  </template>
                </select>
              </td>
              <td v-if="!lernender.editable">
                <template v-if="!lernender.editable">
                  {{ lernender.fldVornameB }}
                </template>
              </td>
              <td :class="{ 'text-end': lernender.editable }">
                <template v-if="!lernender.editable">
                  {{ lernender.fldeMailB }}
                </template>
                <template
                  v-if="
                    lernender.editable &&
                    current_benutzer_rolle == 'Administrator'
                  "
                >
                  <button
                    class="btn btn-danger icon"
                    v-if="!lernender.showDelete"
                    @click="lernender.showDelete = true"
                    title="Löschen"
                  >
                    <i class="bi bi-trash3"></i>
                  </button>
                  <template v-if="lernender.showDelete">
                    <span class="badge bg-danger">wirklich löschen?</span>
                    <button
                      class="btn btn-success icon"
                      @click.once="deleteLernender(lernender)"
                      title="Löschen Bestätigen"
                    >
                      <i class="bi bi-check"></i>
                    </button>
                    <button
                      class="btn btn-danger icon"
                      @click="lernender.showDelete = false"
                      title="Abbrechen"
                    >
                      <i class="bi bi-x"></i>
                    </button>
                  </template>
                </template>
              </td>
              <td v-if="showPapierkorb">
                <button
                  class="btn btn-danger wiederherstellen"
                  @click.once="endDeleteLernender(lernender)"
                  title="endgültig Löschen"
                >
                  <svg
                    id="Ebene_1"
                    data-name="Ebene 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14 16"
                    height="34.5px"
                    width="25px"
                  >
                    <path
                      style="fill: #fff"
                      class="cls-1"
                      d="M5.5,1h3a.5.5,0,0,1,.5.5v1H5v-1A.5.5,0,0,1,5.5,1ZM10,2.5v-1A1.5,1.5,0,0,0,8.5,0h-3A1.5,1.5,0,0,0,4,1.5v1H.5a.5.5,0,0,0,0,1h.538l.853,10.66A2,2,0,0,0,3.885,16h6.23a2,2,0,0,0,1.994-1.84L12.962,3.5H13.5a.5.5,0,0,0,0-1H10Zm1.958,1-.846,10.58a1,1,0,0,1-.997.92H3.885a1,1,0,0,1-.997-.92L2.042,3.5Z"
                    />
                    <path
                      class="cls-1"
                      style="fill: #fff"
                      d="M7.75732,12.64926a1.65724,1.65724,0,0,1-.03515.37011.46691.46691,0,0,1-.35694.373,1.371,1.371,0,0,1-.36523.04,1.3983,1.3983,0,0,1-.36768-.04.47942.47942,0,0,1-.23388-.13183.50763.50763,0,0,1-.12061-.24122,1.65724,1.65724,0,0,1-.03515-.37011,1.724,1.724,0,0,1,.03515-.37891.52928.52928,0,0,1,.12061-.24707.48291.48291,0,0,1,.23388-.13476A1.44111,1.44111,0,0,1,7,11.84848a1.41273,1.41273,0,0,1,.36523.04.50416.50416,0,0,1,.23389.13476.51831.51831,0,0,1,.12305.24707A1.724,1.724,0,0,1,7.75732,12.64926Zm-.1665-2.425a.21856.21856,0,0,1-.03223.09668.204.204,0,0,1-.09423.07226.67147.67147,0,0,1-.18262.0459,2.57349,2.57349,0,0,1-.56641,0,.66645.66645,0,0,1-.17969-.0459.204.204,0,0,1-.09423-.07226.21856.21856,0,0,1-.03223-.09668L6.26953,5.34684a.23539.23539,0,0,1,.03223-.12354.23282.23282,0,0,1,.11572-.08838.83211.83211,0,0,1,.22559-.05127A3.2656,3.2656,0,0,1,7,5.06754a2.81289,2.81289,0,0,1,.35449.01855.92786.92786,0,0,1,.22315.05372.2664.2664,0,0,1,.11816.08593.21259.21259,0,0,1,.03467.1211Z"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  components: {
    "date-picker": DatePicker,
  },
  props: {
    current_benutzer_rolle: "",
  },
  data() {
    return {
      lernende: [],
      bivo: [],
      lehrbetriebe: [],
      betreuer: [],
      gefilterteLernende: [],
      lehrbeginnFilter: [],
      BiVoFilter: [],
      aktiverFilterVorname: "",
      aktiverFilterNachname: "",
      aktiverFilterLehrbetrieb: "",
      aktiverFilterBetreuer: "",
      aktiverFilterBiVo: "",
      aktiverFilterLehrbeginn: "",
      showForm: false,
      showPapierkorb: false,
      deletedLernende: [],
      openedPapierkorb: false,
      newLernender: {
        fldNachnameL: "",
        fldVornameL: "",
        fldeMailL: "",
        fkBiVo: "",
        fldLehrbeginn: "",
        fkLehrbetrieb: "",
        fkBetreuer: "",
      },
    };
  },
  created() {
    this.getLernende();
    axios.get("/api/bivo").then((response) => {
      this.bivo = response.data;
    });
    axios.get("/api/lehrbetriebe").then((response) => {
      this.lehrbetriebe = response.data;
    });
    axios.get("/api/betreuer").then((response) => {
      this.betreuer = response.data;
    });
  },
  methods: {
    getLernende() {
      axios.get("/api/lernende").then((response) => {
        let lernende = response.data;
        for (let i = 0; i < lernende.length; i++) {
          const lernender = lernende[i];
          lernender.editable = false;
          lernender.showDelete = false;
          lernender.edit = {
            fldNachnameL: lernender.fldNachnameL,
            fldVornameL: lernender.fldVornameL,
            fldeMailL: lernender.fldeMailL,
            fkBiVo: lernender.fkBiVo,
            fldLehrbeginn: String(lernender.fldLehrbeginn),
            fkLehrbetrieb: lernender.fkLehrbetrieb,
            fkBetreuer: lernender.fkBetreuer,
          };
        }
        this.lernende = lernende;
        this.updateFilter();
        this.filter();
      });
    },
    papierkorb(showPapierkorb) {
      if (showPapierkorb) {
        if (!this.openedPapierkorb) {
          axios.get("/api/lernende/deleted").then((response) => {
            if (response.status == 200) {
              this.deletedLernende = response.data;
              this.showForm = false;
              for (const key in this.newLernender) {
                this.newLernender[key] = "";
              }
              this.showPapierkorb = true;
              this.updateFilter();
              this.resetFilter();
            }
          });
          this.openedPapierkorb = true;
        } else {
          this.showForm = false;
          for (const key in this.newLernender) {
            this.newLernender[key] = "";
          }
          this.showPapierkorb = true;
          this.updateFilter();
          this.resetFilter();
        }
      } else {
        this.showPapierkorb = false;
        this.updateFilter();
        this.resetFilter();
      }
    },
    filter() {
      for (let i = 0; i < this.lernende.length; i++) {
        const lernender = this.lernende[i];
        lernender.editable = false;
        lernender.showDelete = false;
        for (const key in lernender.edit) {
          lernender.edit[key] = String(lernender[key]);
        }
      }
      let gefilterteLernende = [];
      if (!this.showPapierkorb) {
        gefilterteLernende = this.lernende;
      } else {
        gefilterteLernende = this.deletedLernende;
      }
      let aktiverFilterBetreuer = this.aktiverFilterBetreuer
        .toLowerCase()
        .split(" ");
      if (this.aktiverFilterLehrbeginn !== "") {
        gefilterteLernende = gefilterteLernende.filter((lernender) => {
          return lernender.fldLehrbeginn == this.aktiverFilterLehrbeginn;
        });
      }
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldBiVo.includes(this.aktiverFilterBiVo);
      });
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldVornameL
          .toLowerCase()
          .includes(this.aktiverFilterVorname.toLowerCase());
      });
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldNachnameL
          .toLowerCase()
          .includes(this.aktiverFilterNachname.toLowerCase());
      });
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldLehrbetrieb
          .toLowerCase()
          .includes(this.aktiverFilterLehrbetrieb.toLowerCase());
      });
      for (let i = 0; i < aktiverFilterBetreuer.length; i++) {
        const suchbegriffBetreuer = aktiverFilterBetreuer[i];
        gefilterteLernende = gefilterteLernende.filter((lernender) => {
          if (
            lernender.fldVornameB.toLowerCase().includes(suchbegriffBetreuer) ||
            lernender.fldNachnameB.toLowerCase().includes(suchbegriffBetreuer)
          ) {
            return true;
          }
        });
      }
      this.gefilterteLernende = gefilterteLernende;
    },
    resetFilter() {
      this.aktiverFilterVorname = "";
      this.aktiverFilterNachname = "";
      this.aktiverFilterLehrbetrieb = "";
      this.aktiverFilterBetreuer = "";
      this.aktiverFilterBiVo = "";
      this.aktiverFilterLehrbeginn = "";
      this.filter();
    },
    deleteLernender(lernender) {
      axios
        .post("/api/lernende/" + lernender.pkLernender + "/delete")
        .then((response) => {
          if (response.status == 200) {
            lernender.editable = false;
            lernender.showDelete = false;
            this.lernende.splice(this.lernende.indexOf(lernender), 1);
            this.gefilterteLernende.splice(
              this.gefilterteLernende.indexOf(lernender),
              1
            );
            lernender.fldStatus = 0;
            this.deletedLernende.unshift(lernender);
            this.updateFilter();
          }
        });
    },
    createLernender() {
      this.$refs.submitButton.setAttribute("disabled", true);
      axios.post("/api/lernende", this.newLernender).then((response) => {
        if (response.status == 200) {
          let lernender = response.data;
          lernender.editable = false;
          lernender.showDelete = false;
          lernender.edit = {
            fldNachnameL: lernender.fldNachnameL,
            fldVornameL: lernender.fldVornameL,
            fldeMailL: lernender.fldeMailL,
            fkBiVo: lernender.fkBiVo,
            fldLehrbeginn: String(lernender.fldLehrbeginn),
            fkLehrbetrieb: lernender.fkLehrbetrieb,
            fkBetreuer: lernender.fkBetreuer,
          };
          this.lernende.unshift(lernender);
          this.gefilterteLernende.unshift(lernender);
          this.showForm = false;
          for (const key in this.newLernender) {
            this.newLernender[key] = "";
          }
          this.updateFilter();
        }
      });
    },
    updateLernender(lernender) {
      axios
        .post("/api/lernende/" + lernender.pkLernender, {
          fldVornameL: lernender.edit.fldVornameL,
          fldNachnameL: lernender.edit.fldNachnameL,
          fldeMailL: lernender.edit.fldeMailL,
          fldLehrbeginn: lernender.edit.fldLehrbeginn,
          fkBiVo: lernender.edit.fkBiVo,
          fkLehrbetrieb: lernender.edit.fkLehrbetrieb,
          fkBetreuer: lernender.edit.fkBetreuer,
        })
        .then((response) => {
          if (response.status == 200) {
            let BiVo = lernender.fldBiVo;
            let lehrbeginn = lernender.fldLehrbeginn;
            let newLernender = response.data;
            newLernender.showDelete = false;
            newLernender.editable = false;
            newLernender.edit = {
              fldNachnameL: newLernender.fldNachnameL,
              fldVornameL: newLernender.fldVornameL,
              fldeMailL: newLernender.fldeMailL,
              fkBiVo: newLernender.fkBiVo,
              fldLehrbeginn: String(newLernender.fldLehrbeginn),
              fkLehrbetrieb: newLernender.fkLehrbetrieb,
              fkBetreuer: newLernender.fkBetreuer,
            };
            this.lernende.splice(
              this.lernende.indexOf(lernender),
              1,
              newLernender
            );
            this.gefilterteLernende.splice(
              this.gefilterteLernende.indexOf(lernender),
              1,
              newLernender
            );
            if (
              BiVo !== newLernender.fldBiVo ||
              lehrbeginn !== newLernender.fldLehrbeginn
            ) {
              this.updateFilter();
            }
          }
        });
    },
    restoreLernender(lernender) {
      axios
        .post("/api/lernende/" + lernender.pkLernender + "/restore")
        .then((response) => {
          if (response.status == 200) {
            this.deletedLernende.splice(
              this.deletedLernende.indexOf(lernender),
              1
            );
            this.gefilterteLernende.splice(
              this.gefilterteLernende.indexOf(lernender),
              1
            );
            let restoredLernender = response.data;
            restoredLernender.editable = false;
            restoredLernender.showDelete = false;
            restoredLernender.edit = {
              fldNachnameL: lernender.fldNachnameL,
              fldVornameL: restoredLernender.fldVornameL,
              fldeMailL: restoredLernender.fldeMailL,
              fkBiVo: restoredLernender.fkBiVo,
              fldLehrbeginn: String(restoredLernender.fldLehrbeginn),
              fkLehrbetrieb: restoredLernender.fkLehrbetrieb,
              fkBetreuer: restoredLernender.fkBetreuer,
            };
            this.lernende.unshift(restoredLernender);
            this.updateFilter();
          }
        });
    },
    updateFilter() {
      this.BiVoFilter = [];
      this.lehrbeginnFilter = [];
      if (!this.showPapierkorb) {
        for (let i = 0; i < this.lernende.length; i++) {
          const lernender = this.lernende[i];
          if (!this.BiVoFilter.includes(lernender.fldBiVo)) {
            this.BiVoFilter.push(lernender.fldBiVo);
          }
          if (!this.lehrbeginnFilter.includes(lernender.fldLehrbeginn)) {
            this.lehrbeginnFilter.push(lernender.fldLehrbeginn);
          }
        }
      } else {
        for (let i = 0; i < this.deletedLernende.length; i++) {
          const lernender = this.deletedLernende[i];
          if (!this.BiVoFilter.includes(lernender.fldBiVo)) {
            this.BiVoFilter.push(lernender.fldBiVo);
          }
          if (!this.lehrbeginnFilter.includes(lernender.fldLehrbeginn)) {
            this.lehrbeginnFilter.push(lernender.fldLehrbeginn);
          }
        }
      }
      this.BiVoFilter.sort();
      this.lehrbeginnFilter.sort((a, b) => b - a);
    },
    endDeleteLernender(lernender) {
      if (confirm("Alle Daten werden endgültig gelöscht!\nSind Sie sicher?")) {
        axios
          .delete("/api/lernende/" + lernender.pkLernender)
          .then((response) => {
            if (response.status == 200) {
              this.deletedLernende.splice(this.lernende.indexOf(lernender), 1);
              this.gefilterteLernende.splice(
                this.gefilterteLernende.indexOf(lernender),
                1
              );
              this.updateFilter();
            }
          });
      }
    },
  },
};
</script>
