<template>
  <div>
    <h1>Noten-Eingabe</h1>
    <!-- Filter -->
    <form
      class="row align-items-md-end pt-2 pb-md-3 sticky-md-top bg-light shadow-sm mb-2 mx-md-0"
      @submit.prevent="filter()"
    >
      <div class="col-12 mb-2 col-md mb-md-0 col-lg-auto">
        <label for="BiVoFilter">BiVo</label>
        <select class="form-select" id="BiVoFilter" v-model="aktiverFilterBiVo">
          <option></option>
          <template v-for="filter in BiVoFilter">
            <option :key="filter" :value="filter">{{ filter }}</option>
          </template>
        </select>
      </div>
      <div class="col-12 mb-2 col-md mb-md-0 col-lg-auto">
        <label for="lehrbeginnFilter">Lehrbeginn</label>
        <select
          class="form-select"
          id="lehrbeginnFilter"
          v-model="aktiverFilterLehrbeginn"
        >
          <option></option>
          <template v-for="filter in lehrbeginnFilter">
            <option :key="filter" :value="filter">{{ filter }}</option>
          </template>
        </select>
      </div>
      <div class="col-12 mb-2 col-md mb-md-0 col-lg-auto">
        <label for="modulNrFilter">Modul-Nr.</label>
        <input
          id="modulNrFilter"
          type="text"
          class="form-control"
          v-model="aktiverFilterModulNr"
        />
      </div>
      <div class="col-12 mb-2 col-md-auto mb-md-0">
        <button
          type="submit"
          class="btn btn-primary icon d-inline-flex align-items-center gap-1 w-100 justify-content-center"
        >
          <i class="bi bi-funnel"></i>Filtern
        </button>
      </div>
      <div
        class="col-12 mb-2 text-md-end mt-md-2 mb-md-0 col-lg-auto mt-lg-0"
        v-if="modulnoten.length !== gefilterteModulnoten.length"
      >
        <button class="btn btn-link" @click="resetFilter()">
          Filter zurücksetzen
        </button>
      </div>
    </form>
    <div
      v-if="modulnoten.length && !gefilterteModulnoten.length"
      class="alert alert-danger text-center mt-3"
    >
      Es wurden keine Ergebnisse gefunden.
    </div>
    <!-- Tabelle -->
    <div class="table-responsive" v-if="gefilterteModulnoten.length">
      <table class="table text-nowrap align-middle">
        <thead>
          <tr>
            <th>Lehrbeginn</th>
            <th>Nachname</th>
            <th>Vorname</th>
            <th>Firma</th>
            <th>Modul-Nr.</th>
            <th>Modulname</th>
            <th>Note</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(modulnote, index) in gefilterteModulnoten">
            <tr :key="index">
              <td>{{ modulnote.fldLehrbeginn }}</td>
              <td>{{ modulnote.fldNachnameL }}</td>
              <td>{{ modulnote.fldVornameL }}</td>
              <td>
                {{ modulnote.fldLehrbetrieb }}
              </td>
              <td>{{ modulnote.fldModulNr }}</td>
              <td>{{ modulnote.fldModulname }}</td>
              <td>
                <template v-if="!modulnote.editable">
                  {{ modulnote.fldNote }}
                </template>
                <select
                  v-model="modulnote.edit.fldNote"
                  class="form-select"
                  v-if="modulnote.editable"
                >
                  <option></option>
                  <template v-for="note in notenAuswahl">
                    <option
                      :selected="modulnote.fldNote == note"
                      :key="note"
                      :value="note"
                    >
                      {{ note }}
                    </option>
                  </template>
                </select>
              </td>
              <td class="text-end">
                <button
                  class="btn btn-primary icon"
                  v-if="!modulnote.editable"
                  @click="modulnote.editable = true"
                  title="Bearbeiten"
                >
                  <i class="bi bi-pencil"></i>
                </button>
                <template v-if="modulnote.editable">
                  <button
                    @click.once="saveNote(modulnote, index)"
                    class="btn btn-success icon"
                    title="Speichern"
                  >
                    <i class="bi bi-check"></i>
                  </button>
                  <button
                    @click="
                      modulnote.editable = false;
                      modulnote.edit.fldNote = modulnote.fldNote;
                    "
                    class="btn btn-danger icon"
                    title="Abbrechen"
                  >
                    <i class="bi bi-x"></i>
                  </button>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      modulnoten: [],
      gefilterteModulnoten: [],
      lehrbeginnFilter: [],
      BiVoFilter: [],
      aktiverFilterBiVo: "",
      aktiverFilterLehrbeginn: "",
      aktiverFilterModulNr: "",
      notenAuswahl: [
        "6",
        "5.5",
        "5",
        "4.5",
        "4",
        "3.5",
        "3",
        "2.5",
        "2",
        "1.5",
        "1",
        "Dispens",
      ],
    };
  },
  created() {
    this.getModulnoten();
  },
  methods: {
    getModulnoten() {
      axios.get("/api/modulnoten").then((response) => {
        let modulnoten = response.data;
        for (let i = 0; i < modulnoten.length; i++) {
          const modulnote = modulnoten[i];
          if (!this.lehrbeginnFilter.includes(modulnote.fldLehrbeginn)) {
            this.lehrbeginnFilter.push(modulnote.fldLehrbeginn);
          }
          if (!this.BiVoFilter.includes(modulnote.fldBiVo)) {
            this.BiVoFilter.push(modulnote.fldBiVo);
          }
          modulnote.editable = false;
          modulnote.edit = {
            fldNote: modulnote.fldNote,
          };
        }
        this.lehrbeginnFilter.sort((a, b) => b - a);
        this.BiVoFilter.sort();
        this.modulnoten = modulnoten;
        this.filter();
      });
    },
    filter() {
      for (let i = 0; i < this.modulnoten.length; i++) {
        const modulnote = this.modulnoten[i];
        modulnote.editable = false;
        modulnote.edit.fldNote = modulnote.fldNote;
      }
      let gefilterteModulnoten = this.modulnoten;
      if (this.aktiverFilterLehrbeginn !== "") {
        gefilterteModulnoten = gefilterteModulnoten.filter((modulnote) => {
          return modulnote.fldLehrbeginn == this.aktiverFilterLehrbeginn;
        });
      }
      gefilterteModulnoten = gefilterteModulnoten.filter((modulnote) => {
        return modulnote.fldBiVo.includes(this.aktiverFilterBiVo);
      });
      gefilterteModulnoten = gefilterteModulnoten.filter((modulnote) => {
        return modulnote.fldModulNr
          .toLowerCase()
          .includes(this.aktiverFilterModulNr.toLowerCase());
      });
      this.gefilterteModulnoten = gefilterteModulnoten;
    },
    resetFilter() {
      this.aktiverFilterBiVo = "";
      this.aktiverFilterLehrbeginn = "";
      this.aktiverFilterModulNr = "";
      this.filter();
    },
    saveNote(modulnote, index) {
      axios
        .post("/api/modulnoten", {
          pkModulnote: modulnote.pkModulnote,
          pkLernender: modulnote.pkLernender,
          pkModul: modulnote.pkModul,
          fldNote: modulnote.edit.fldNote,
        })
        .then((response) => {
          if (response.data) {
            let newModulnote = response.data;
            newModulnote.editable = false;
            newModulnote.edit = {
              fldNote: newModulnote.fldNote,
            };
            this.modulnoten.splice(
              this.modulnoten.indexOf(modulnote),
              1,
              newModulnote
            );
            this.gefilterteModulnoten.splice(index, 1, newModulnote);
          } else {
            this.gefilterteModulnoten[index].editable = false;
            this.gefilterteModulnoten[index].edit.fldNote =
              this.gefilterteModulnoten[index].fldNote;
          }
        });
    },
  },
};
</script>