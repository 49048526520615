<template>
  <div>
    <h1>Benutzer</h1>
    <h2 class="fs-5">Ihr Profil</h2>
    <!-- Tabelle eingeloggter User -->
    <div class="table-responsive">
      <table class="table text-nowrap align-middle">
        <thead>
          <tr>
            <th></th>
            <th>E-Mail-Adresse</th>
            <th>Rolle</th>
            <th>Zugriff auf BiVo</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <button
                class="btn btn-primary icon"
                v-if="!currentBenutzer.editable"
                @click="currentBenutzer.editable = true"
                title="Bearbeiten"
              >
                <i class="bi bi-pencil"></i>
              </button>
              <template v-if="currentBenutzer.editable">
                <form
                  id="updateCurrentBenutzer"
                  @submit.prevent="updateBenutzer(currentBenutzer.id)"
                >
                  <button
                    type="submit"
                    class="btn btn-success icon"
                    title="Speichern"
                  >
                    <i class="bi bi-check"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger icon"
                    @click="
                      currentBenutzer.editable = false;
                      currentBenutzer.editeMail = currentBenutzer.email;
                      currentBenutzer.erroreMail = false;
                    "
                    title="Abbrechen"
                  >
                    <i class="bi bi-x"></i>
                  </button>
                </form>
              </template>
            </td>
            <td>
              <template v-if="!currentBenutzer.editable">
                {{ currentBenutzer.email }}
              </template>
              <input
                type="email"
                form="updateCurrentBenutzer"
                class="form-control"
                :class="{ 'is-invalid': currentBenutzer.erroreMail }"
                :id="'editeMail' + currentBenutzer.id"
                v-model="currentBenutzer.editeMail"
                v-if="currentBenutzer.editable"
                required
              />
              <span
                class="invalid-feedback d-block"
                role="alert"
                v-if="currentBenutzer.erroreMail"
              >
                <strong>{{ currentBenutzer.erroreMail }}</strong>
              </span>
            </td>
            <td>
              {{ currentBenutzer.fldRolle }}
            </td>
            <td>
              <template v-if="currentBenutzer.fldRolle == 'Administrator'">
                Alle
              </template>
              <template v-if="currentBenutzer.fldRolle == 'Teacher'">
                <template v-for="bivo in bivo">
                  <span
                    v-if="
                      currentBenutzer.bivo &&
                      currentBenutzer.bivo.includes(bivo.pkBiVo)
                    "
                    :key="bivo.pkBiVo"
                  >
                    {{ bivo.fldBiVo
                    }}<template
                      v-if="
                        currentBenutzer.bivo.length - 1 !==
                        currentBenutzer.bivo.indexOf(bivo.pkBiVo)
                      "
                      >,</template
                    >
                  </span>
                </template>
              </template>
            </td>
            <td>
              <a class="btn btn-link" href="/password/reset">
                Passwort zurücksetzen
              </a>
            </td>
            <td class="text-end">
              <a class="btn btn-primary" href="/logout">Logout</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template v-if="currentBenutzer.fldRolle == 'Administrator'">
      <h2 class="fs-5">Alle Benutzer</h2>
      <!-- Tabelle alle User -->
      <table class="table text-nowrap align-middle">
        <thead>
          <tr>
            <th>
              <a
                class="btn btn-primary icon"
                href="/register"
                title="neuen Benutzer erfassen"
              >
                <i class="bi bi-plus"></i>
              </a>
            </th>
            <th>E-Mail-Adresse</th>
            <th>Rolle</th>
            <th class="w-25">Zugriff auf BiVo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(benutzer, index) in benutzer">
            <tr
              :key="benutzer.id"
              v-if="benutzer.email !== currentBenutzer.email"
            >
              <td>
                <button
                  class="btn btn-primary icon"
                  v-if="!benutzer.editable"
                  @click="benutzer.editable = true"
                  title="Bearbeiten"
                >
                  <i class="bi bi-pencil"></i>
                </button>
                <template v-if="benutzer.editable">
                  <form
                    :id="'updateBenutzer' + index"
                    @submit.prevent="updateBenutzer(benutzer.id, index)"
                  >
                    <button
                      type="submit"
                      class="btn btn-success icon"
                      title="Speichern"
                    >
                      <i class="bi bi-check"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger icon"
                      @click="
                        benutzer.editable = false;
                        benutzer.showDelete = false;
                        benutzer.erroreMail = false;
                        benutzer.errorBiVo = false;
                        benutzer.editRolle = benutzer.fldRolle;
                        benutzer.editeMail = benutzer.email;
                        benutzer.editBiVo = benutzer.bivo;
                      "
                      title="Abbrechen"
                    >
                      <i class="bi bi-x"></i>
                    </button>
                  </form>
                </template>
              </td>
              <td>
                <template v-if="!benutzer.editable">
                  {{ benutzer.email }}
                </template>
                <input
                  type="email"
                  :form="'updateBenutzer' + index"
                  class="form-control"
                  :class="{ 'is-invalid': benutzer.erroreMail }"
                  :id="'editeMail' + benutzer.id"
                  v-model="benutzer.editeMail"
                  v-if="benutzer.editable"
                  required
                />
                <span
                  class="invalid-feedback d-block"
                  role="alert"
                  v-if="benutzer.erroreMail"
                >
                  <strong>{{ benutzer.erroreMail }}</strong>
                </span>
              </td>
              <td>
                <template v-if="!benutzer.editable">
                  {{ benutzer.fldRolle }}
                </template>
                <select
                  :form="'updateBenutzer' + index"
                  class="form-select"
                  :id="'editRolle' + benutzer.id"
                  v-if="benutzer.editable"
                  required
                  v-model="benutzer.editRolle"
                >
                  <option
                    value="Administrator"
                    :selected="benutzer.fldRolle == 'Administrator'"
                  >
                    Administrator
                  </option>
                  <option
                    value="Teacher"
                    :selected="benutzer.fldRolle == 'Teacher'"
                  >
                    Teacher
                  </option>
                </select>
              </td>
              <td>
                <template v-if="benutzer.editRolle == 'Administrator'">
                  Alle
                </template>
                <!-- Anzeige Zugriffsberechtigungen -->
                <template v-if="benutzer.editRolle == 'Teacher'">
                  <template v-for="(benutzerbivo, index) in benutzer.bivo">
                    <span v-if="!benutzer.editable" :key="benutzerbivo.pkBiVo">
                      {{
                        bivo.find((bivo) => bivo.pkBiVo == benutzerbivo.pkBiVo)
                          .fldBiVo
                      }}<template v-if="index !== benutzer.bivo.length - 1"
                        >,</template
                      >
                    </span>
                  </template>
                  <template v-if="benutzer.editable">
                    <div
                      :class="{
                        'is-invalid':
                          benutzer.errorBiVo && !benutzer.editBiVo.length,
                      }"
                    >
                      <multi-select
                        :options="bivo"
                        label="fldBiVo"
                        trackBy="pkBiVo"
                        v-model="benutzer.editBiVo"
                        :multiple="true"
                        :close-on-select="false"
                        :searchable="false"
                        :show-labels="false"
                        :placeholder="
                          benutzer.errorBiVo ? benutzer.errorBiVo : ''
                        "
                        :hideSelected="true"
                      ></multi-select>
                    </div>
                  </template>
                </template>
              </td>
              <td class="text-end">
                <template v-if="benutzer.editable">
                  <button
                    class="btn btn-danger icon"
                    v-if="!benutzer.showDelete"
                    @click="benutzer.showDelete = true"
                    title="Löschen"
                  >
                    <i class="bi bi-trash3"></i>
                  </button>
                  <template v-if="benutzer.showDelete">
                    <span class="badge bg-danger">wirklich löschen?</span>
                    <button
                      class="btn btn-success icon"
                      @click.once="deleteBenutzer(benutzer.id, index)"
                      title="Löschen Bestätigen"
                    >
                      <i class="bi bi-check"></i>
                    </button>
                    <button
                      class="btn btn-danger icon"
                      @click="benutzer.showDelete = false"
                      title="Abbrechen"
                    >
                      <i class="bi bi-x"></i>
                    </button>
                  </template>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
// Node-Package-Referenz: https://vue-multiselect.js.org/
import MultiSelect from "vue-multiselect";
import MultiSelectCSS from "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: {
    "multi-select": MultiSelect,
  },
  data() {
    return {
      benutzer: [],
      currentBenutzer: {},
      bivo: [],
    };
  },
  created() {
    axios.get("/api/bivo").then((response) => {
      if (response.status == 200) {
        this.bivo = response.data;
      }
    });
    axios.get("/api/currentBenutzer").then((response) => {
      if (response.status == 200) {
        let currentBenutzer = response.data;
        currentBenutzer.editable = false;
        currentBenutzer.editeMail = currentBenutzer.email;
        currentBenutzer.erroreMail = false;
        this.currentBenutzer = currentBenutzer;
        this.getBenutzer();
        if (this.currentBenutzer.fldRolle == "Teacher") {
          axios
            .get("/api/userbivo/" + this.currentBenutzer.id)
            .then((response) => {
              if (response.status == 200) {
                Vue.set(this.currentBenutzer, "bivo", response.data);
              }
            });
        }
      }
    });
  },
  methods: {
    getBenutzer() {
      axios.get("/api/benutzer").then((response) => {
        let user = response.data;
        for (let i = 0; i < user.length; i++) {
          const benutzer = user[i];
          benutzer.editable = false;
          benutzer.showDelete = false;
          benutzer.erroreMail = false;
          benutzer.errorBiVo = false;
          benutzer.bivo = [];
          benutzer.editRolle = benutzer.fldRolle;
          benutzer.editeMail = benutzer.email;
          axios.get("/api/userbivo/" + benutzer.id).then((response) => {
            benutzer.bivo = response.data;
            benutzer.bivo = this.bivo.filter((bivo) => {
              return benutzer.bivo.includes(bivo.pkBiVo);
            });
            Vue.set(benutzer, "editBiVo", benutzer.bivo);
          });
        }
        this.benutzer = user;
      });
    },
    deleteBenutzer(id, index) {
      axios.delete("/api/benutzer/" + id).then((response) => {
        if (response.status == 200) {
          this.benutzer.splice(index, 1);
        }
      });
    },
    updateBenutzer(id, index) {
      if (id !== this.currentBenutzer.id) {
        this.benutzer[index].erroreMail = false;
        this.benutzer[index].errorBiVo = false;
      } else {
        this.currentBenutzer.erroreMail = false;
      }
      let user = {
        fldeMailU: document.getElementById("editeMail" + id).value,
        fldRolle: this.currentBenutzer.fldRolle,
        bivo: [],
      };
      let sendable = true;
      if (id !== this.currentBenutzer.id) {
        user.fldRolle = this.benutzer[index].editRolle;
        if (user.fldRolle == "Teacher") {
          user.bivo = this.benutzer[index].editBiVo.map((bivo) => bivo.pkBiVo);
          if (!user.bivo.length) {
            sendable = false;
            this.benutzer[index].errorBiVo =
              "Wählen Sie mindestens eine BiVo aus, auf die der Benutzer Zugriff haben sollte.";
          }
        }
      }
      if (
        this.benutzer
          .filter((benutzer) => {
            return benutzer.id !== id;
          })
          .filter((benutzer) => {
            return benutzer.email == user.fldeMailU;
          }).length
      ) {
        sendable = false;
        if (id !== this.currentBenutzer.id) {
          this.benutzer[index].erroreMail =
            "Es gibt bereits einen Benutzer mit dieser E-Mail-Adresse.";
        }
        if (id == this.currentBenutzer.id) {
          this.currentBenutzer.erroreMail =
            "Es gibt bereits einen Benutzer mit dieser E-Mail-Adresse.";
        }
      }
      if (sendable) {
        axios.post("/api/benutzer/" + id + "/update", user).then((response) => {
          if (response.status == 200) {
            if (id == this.currentBenutzer.id) {
              alert(
                "Ihre E-Mail-Adresse wurde erfolgreich geändert.\nSie können sich ab sofort mit der neuen E-Mail Adresse anmelden.\n\nSie werden nun abgemeldet."
              );
              window.location.href = "/";
            } else {
              let benutzer = response.data;
              benutzer.editable = false;
              benutzer.showDelete = false;
              benutzer.erroreMail = false;
              benutzer.errorBiVo = false;
              benutzer.editRolle = benutzer.fldRolle;
              benutzer.editeMail = benutzer.email;
              benutzer.bivo = user.bivo;
              benutzer.bivo = this.bivo.filter((bivo) => {
                return benutzer.bivo.includes(bivo.pkBiVo);
              });
              Vue.set(benutzer, "editBiVo", benutzer.bivo);
              this.benutzer.splice(index, 1, benutzer);
            }
          }
        });
      }
    },
  },
};
</script>