<template>
  <div>
    <h1>BiVos</h1>
    <div class="alert alert-warning">
      Nach Erstellung neuer BiVos daran denken, diese den entsprechenden
      Benutzern zuzuweisen!
    </div>
    <form id="createBiVo" @submit.prevent="createBiVo()"></form>
    <div class="col-lg-6 table-responsive">
      <table class="table text-nowrap align-middle">
        <thead>
          <tr>
            <th>
              <button
                class="btn btn-primary icon"
                @click="showForm = true"
                title="neue BiVo erfassen"
              >
                <i class="bi bi-plus"></i>
              </button>
            </th>
            <th>Bezeichnung</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showForm" class="align-top">
            <td></td>
            <td>
              <input
                form="createBiVo"
                class="form-control"
                :class="{ 'is-invalid': errorName }"
                type="text"
                v-model="newBiVo.fldBiVo"
                required
              />
              <span
                class="invalid-feedback d-block"
                role="alert"
                v-if="errorName"
              >
                <strong>{{ errorName }}</strong>
              </span>
            </td>
            <td>
              <button
                type="submit"
                form="createBiVo"
                class="btn btn-success icon"
                title="Speichern"
                ref="submitButton"
              >
                <i class="bi bi-check"></i>
              </button>
              <button
                @click="
                  showForm = false;
                  errorName = false;
                  newBiVo.fldBiVo = '';
                "
                class="btn btn-danger icon"
                title="Abbrechen"
              >
                <i class="bi bi-x"></i>
              </button>
            </td>
          </tr>
          <template v-for="(bivo, index) in bivo">
            <tr :key="bivo.pkBiVo" :class="{ 'align-top': bivo.editable }">
              <td>
                <button
                  class="btn btn-primary icon"
                  v-if="!bivo.editable"
                  @click="bivo.editable = true"
                  title="Bearbeiten"
                >
                  <i class="bi bi-pencil"></i>
                </button>
                <template v-if="bivo.editable">
                  <form
                    :id="'updateBiVo' + index"
                    @submit.prevent="updateBiVo(bivo)"
                  >
                    <button
                      type="submit"
                      class="btn btn-success icon"
                      title="Speichern"
                    >
                      <i class="bi bi-check"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger icon"
                      @click="
                        bivo.editable = false;
                        bivo.errorName = false;
                        bivo.edit.fldBiVo = bivo.fldBiVo;
                      "
                      title="Abbrechen"
                    >
                      <i class="bi bi-x"></i>
                    </button>
                  </form>
                </template>
              </td>
              <td>
                <template v-if="!bivo.editable">
                  {{ bivo.fldBiVo }}
                </template>
                <input
                  type="text"
                  :form="'updateBiVo' + index"
                  class="form-control"
                  :class="{ 'is-invalid': bivo.errorName }"
                  v-model="bivo.edit.fldBiVo"
                  v-if="bivo.editable"
                  required
                />
                <span
                  class="invalid-feedback d-block"
                  role="alert"
                  v-if="bivo.errorName"
                >
                  <strong>{{ bivo.errorName }}</strong>
                </span>
              </td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bivo: [],
      showForm: false,
      errorName: false,
      newBiVo: {
        fldBiVo: "",
      },
    };
  },
  created() {
    axios.get("/api/bivo").then((response) => {
      let bivos = response.data;
      for (let i = 0; i < bivos.length; i++) {
        const bivo = bivos[i];
        bivo.editable = false;
        bivo.errorName = false;
        bivo.edit = {
          fldBiVo: bivo.fldBiVo,
        };
      }
      this.bivo = bivos;
    });
  },
  methods: {
    createBiVo() {
      let sendable = true;
      if (
        this.bivo.filter((bivo) => {
          return (
            bivo.fldBiVo.toLowerCase() == this.newBiVo.fldBiVo.toLowerCase()
          );
        }).length
      ) {
        sendable = false;
        this.errorName = "Es gibt bereits eine BiVo mit dieser Bezeichnung.";
      }
      if (sendable) {
        this.$refs.submitButton.setAttribute("disabled", true);
        axios.post("/api/bivo", this.newBiVo).then((response) => {
          if (response.status == 200) {
            let bivo = response.data;
            bivo.editable = false;
            bivo.errorName = false;
            bivo.edit = {
              fldBiVo: bivo.fldBiVo,
            };
            this.bivo.unshift(bivo);
            this.showForm = false;
            this.newBiVo.fldBiVo = "";
          }
        });
        this.errorName = false;
      }
    },
    updateBiVo(bivo) {
      let sendable = true;
      if (
        this.bivo
          .filter((row) => {
            return row.pkBiVo !== bivo.pkBiVo;
          })
          .filter((row) => {
            return row.fldBiVo.toLowerCase() == bivo.edit.fldBiVo.toLowerCase();
          }).length
      ) {
        sendable = false;
        this.bivo[this.bivo.indexOf(bivo)].errorName =
          "Es gibt bereits eine BiVo mit dieser Bezeichnung.";
      }
      if (sendable) {
        axios.post("/api/bivo/" + bivo.pkBiVo, bivo.edit).then((response) => {
          if (response.status == 200) {
            let newBiVo = response.data;
            newBiVo.editable = false;
            newBiVo.errorName = false;
            newBiVo.edit = {
              fldBiVo: newBiVo.fldBiVo,
            };
            this.bivo.splice(this.bivo.indexOf(bivo), 1, newBiVo);
          }
        });
      }
    },
  },
};
</script>