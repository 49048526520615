<template>
  <div>
    <h1>Modul-Erstellung</h1>
    <form id="createModul" @submit.prevent="createModul()"></form>
    <div class="table-responsive">
      <table class="table text-nowrap align-middle">
        <thead>
          <tr>
            <th>
              <button
                class="btn btn-primary icon"
                @click="showForm = true"
                title="neues Modul erfassen"
              >
                <i class="bi bi-plus"></i>
              </button>
            </th>
            <th>Modul-Nr.</th>
            <th>Bezeichnung</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showForm" class="align-top">
            <td></td>
            <td>
              <input
                form="createModul"
                class="form-control"
                :class="{ 'is-invalid': errorNummer }"
                type="text"
                v-model="newModul.fldModulNr"
                required
              />
              <span
                class="invalid-feedback d-block"
                role="alert"
                v-if="errorNummer"
              >
                <strong>{{ errorNummer }}</strong>
              </span>
            </td>
            <td>
              <input
                form="createModul"
                class="form-control"
                type="text"
                v-model="newModul.fldModulname"
                required
              />
            </td>
            <td>
              <button
                type="submit"
                form="createModul"
                class="btn btn-success icon"
                title="Speichern"
                ref="submitButton"
              >
                <i class="bi bi-check"></i>
              </button>
              <button
                @click="
                  showForm = false;
                  errorNummer = false;
                  {
                    for (const key in newModul) {
                      newModul[key] = '';
                    }
                  }
                  newModul.fldÜK = false;
                "
                class="btn btn-danger icon"
                title="Abbrechen"
              >
                <i class="bi bi-x"></i>
              </button>
            </td>
          </tr>
          <template v-for="(modul, index) in module">
            <tr :key="modul.pkModul" :class="{ 'align-top': modul.editable }">
              <td>
                <button
                  class="btn btn-primary icon"
                  v-if="!modul.editable"
                  @click="modul.editable = true"
                  title="Bearbeiten"
                >
                  <i class="bi bi-pencil"></i>
                </button>
                <template v-if="modul.editable">
                  <form
                    :id="'updateModul' + index"
                    @submit.prevent="updateModul(modul)"
                  >
                    <button
                      type="submit"
                      class="btn btn-success icon"
                      title="Speichern"
                    >
                      <i class="bi bi-check"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger icon"
                      @click="
                        modul.editable = false;
                        modul.errorNummer = false;
                        {
                          for (const key in modul.edit) {
                            modul.edit[key] = modul[key];
                          }
                        }
                      "
                      title="Abbrechen"
                    >
                      <i class="bi bi-x"></i>
                    </button>
                  </form>
                </template>
              </td>
              <td>
                <template v-if="!modul.editable">
                  {{ modul.fldModulNr }}
                </template>
                <input
                  type="text"
                  :form="'updateModul' + index"
                  class="form-control"
                  :class="{ 'is-invalid': modul.errorNummer }"
                  v-model="modul.edit.fldModulNr"
                  v-if="modul.editable"
                  required
                />
                <span
                  class="invalid-feedback d-block"
                  role="alert"
                  v-if="modul.errorNummer"
                >
                  <strong>{{ modul.errorNummer }}</strong>
                </span>
              </td>
              <td>
                <template v-if="!modul.editable">
                  {{ modul.fldModulname }}
                </template>
                <input
                  type="text"
                  :form="'updateModul' + index"
                  class="form-control"
                  v-model="modul.edit.fldModulname"
                  v-if="modul.editable"
                  required
                />
              </td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      module: [],
      showForm: false,
      errorNummer: false,
      newModul: {
        fldModulNr: "",
        fldModulname: "",
      },
    };
  },
  created() {
    axios.get("/api/module").then((response) => {
      let module = response.data;
      for (let i = 0; i < module.length; i++) {
        const modul = module[i];
        modul.editable = false;
        modul.errorNummer = false;
        modul.edit = {
          fldModulNr: modul.fldModulNr,
          fldModulname: modul.fldModulname,
        };
      }
      this.module = module;
    });
  },
  methods: {
    createModul() {
      let sendable = true;
      if (
        this.module.filter((modul) => {
          return (
            modul.fldModulNr.toLowerCase() ==
            this.newModul.fldModulNr.toLowerCase()
          );
        }).length
      ) {
        sendable = false;
        this.errorNummer = "Es gibt bereits ein Modul mit dieser Modul-Nr.";
      }
      if (sendable) {
        this.$refs.submitButton.setAttribute("disabled", true);
        axios.post("/api/module", this.newModul).then((response) => {
          if (response.status == 200) {
            let modul = response.data;
            modul.editable = false;
            modul.errorNummer = false;
            modul.edit = {
              fldModulNr: modul.fldModulNr,
              fldModulname: modul.fldModulname,
            };
            this.module.unshift(modul);
            this.showForm = false;
            for (const key in this.newModul) {
              this.newModul[key] = "";
            }
          }
        });
        this.errorNummer = false;
      }
    },
    updateModul(modul) {
      let sendable = true;
      if (
        this.module
          .filter((row) => {
            return row.pkModul !== modul.pkModul;
          })
          .filter((row) => {
            return (
              row.fldModulNr.toLowerCase() ==
              modul.edit.fldModulNr.toLowerCase()
            );
          }).length
      ) {
        sendable = false;
        this.module[this.module.indexOf(modul)].errorNummer =
          "Es gibt bereits ein Modul mit dieser Modul-Nr.";
      }
      if (sendable) {
        axios
          .post("/api/module/" + modul.pkModul, modul.edit)
          .then((response) => {
            if (response.status == 200) {
              let newModul = response.data;
              newModul.editable = false;
              newModul.errorNummer = false;
              newModul.edit = {
                fldModulNr: newModul.fldModulNr,
                fldModulname: newModul.fldModulname,
              };
              this.module.splice(this.module.indexOf(modul), 1, newModul);
            }
          });
      }
    },
  },
};
</script>