<template>
  <div>
    <h1>Betreuer</h1>
    <form id="createBetreuer" @submit.prevent="createBetreuer()"></form>
    <div class="table-responsive">
      <table class="table text-nowrap align-middle">
        <thead>
          <tr>
            <th>
              <button
                class="btn btn-primary icon"
                @click="showForm = true"
                title="neuen Betreuer erfassen"
              >
                <i class="bi bi-plus"></i>
              </button>
            </th>
            <th>Nachname</th>
            <th>Vorname</th>
            <th>E-Mail-Adresse</th>
            <th>Firma</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showForm">
            <td></td>
            <td>
              <input
                form="createBetreuer"
                class="form-control"
                type="text"
                v-model="newBetreuer.fldNachnameB"
                required
              />
            </td>
            <td>
              <input
                form="createBetreuer"
                class="form-control"
                type="text"
                v-model="newBetreuer.fldVornameB"
                required
              />
            </td>
            <td>
              <input
                form="createBetreuer"
                class="form-control"
                type="email"
                v-model="newBetreuer.fldeMailB"
                required
              />
            </td>
            <td>
              <select
                form="createBetreuer"
                class="form-select"
                v-model="newBetreuer.fkLehrbetrieb"
                required
              >
                <option></option>
                <template v-for="lehrbetrieb in lehrbetriebe">
                  <option
                    :value="lehrbetrieb.pkLehrbetrieb"
                    :key="lehrbetrieb.pkLehrbetrieb"
                  >
                    {{ lehrbetrieb.fldLehrbetrieb }}
                  </option>
                </template>
              </select>
            </td>
            <td class="align-top">
              <button
                type="submit"
                form="createBetreuer"
                class="btn btn-success icon"
                title="Speichern"
                ref="submitButton"
              >
                <i class="bi bi-check"></i>
              </button>
              <button
                @click="
                  showForm = false;
                  erroreMail = false;
                  {
                    for (const key in newBetreuer) {
                      newBetreuer[key] = '';
                    }
                  }
                "
                class="btn btn-danger icon"
                title="Abbrechen"
              >
                <i class="bi bi-x"></i>
              </button>
            </td>
          </tr>
          <template v-for="(betreuer, index) in betreuer">
            <tr :key="betreuer.pkBetreuer">
              <td>
                <button
                  class="btn btn-primary icon"
                  v-if="!betreuer.editable"
                  @click="betreuer.editable = true"
                  title="Bearbeiten"
                >
                  <i class="bi bi-pencil"></i>
                </button>
                <template v-if="betreuer.editable">
                  <form
                    :id="'updateBetreuer' + index"
                    @submit.prevent="updateBetreuer(betreuer)"
                  >
                    <button
                      type="submit"
                      class="btn btn-success icon"
                      title="Speichern"
                    >
                      <i class="bi bi-check"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger icon"
                      @click="
                        betreuer.editable = false;
                        betreuer.showDelete = false;
                        {
                          for (const key in betreuer.edit) {
                            betreuer.edit[key] = String(betreuer[key]);
                          }
                        }
                      "
                      title="Abbrechen"
                    >
                      <i class="bi bi-x"></i>
                    </button>
                  </form>
                </template>
              </td>
              <td>
                <template v-if="!betreuer.editable">
                  {{ betreuer.fldNachnameB }}
                </template>
                <input
                  type="text"
                  :form="'updateBetreuer' + index"
                  class="form-control"
                  v-model="betreuer.edit.fldNachnameB"
                  v-if="betreuer.editable"
                  required
                />
              </td>
              <td>
                <template v-if="!betreuer.editable">
                  {{ betreuer.fldVornameB }}
                </template>
                <input
                  type="text"
                  :form="'updateBetreuer' + index"
                  class="form-control"
                  v-model="betreuer.edit.fldVornameB"
                  v-if="betreuer.editable"
                  required
                />
              </td>
              <td>
                <template v-if="!betreuer.editable">
                  {{ betreuer.fldeMailB }}
                </template>
                <input
                  type="email"
                  :form="'updateBetreuer' + index"
                  class="form-control"
                  v-model="betreuer.edit.fldeMailB"
                  v-if="betreuer.editable"
                  required
                />
              </td>
              <td>
                <template v-if="!betreuer.editable">
                  {{ betreuer.fldLehrbetrieb }}
                </template>
                <select
                  :form="'udpateBetreuer' + index"
                  class="form-select"
                  v-model="betreuer.edit.fkLehrbetrieb"
                  v-if="betreuer.editable"
                  required
                >
                  <option></option>
                  <template v-for="lehrbetrieb in lehrbetriebe">
                    <option
                      :key="lehrbetrieb.pkLehrbetrieb"
                      :value="lehrbetrieb.pkLehrbetrieb"
                      :selected="
                        betreuer.fkLehrbetrieb == lehrbetrieb.pkLehrbetrieb
                      "
                    >
                      {{ lehrbetrieb.fldLehrbetrieb }}
                    </option>
                  </template>
                </select>
              </td>
              <td class="text-end">
                <button
                  class="btn btn-danger icon"
                  v-if="!betreuer.showDelete"
                  @click="betreuer.showDelete = true"
                  title="Löschen"
                >
                  <i class="bi bi-trash3"></i>
                </button>
                <template v-if="betreuer.showDelete">
                  <span class="badge bg-danger">wirklich löschen?</span>
                  <button
                    class="btn btn-success icon"
                    @click.once="deleteBetreuer(betreuer)"
                    title="Löschen Bestätigen"
                  >
                    <i class="bi bi-check"></i>
                  </button>
                  <button
                    class="btn btn-danger icon"
                    @click="betreuer.showDelete = false"
                    title="Abbrechen"
                  >
                    <i class="bi bi-x"></i>
                  </button>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showForm: false,
      lehrbetriebe: [],
      betreuer: [],
      newBetreuer: {
        fldNachnameB: "",
        fldVornameB: "",
        fldeMailB: "",
        fkLehrbetrieb: "",
      },
    };
  },
  created() {
    axios.get("/api/betreuer").then((response) => {
      let betreuer = response.data;
      for (let i = 0; i < betreuer.length; i++) {
        betreuer[i].editable = false;
        betreuer[i].showDelete = false;
        betreuer[i].edit = {
          fldNachnameB: betreuer[i].fldNachnameB,
          fldVornameB: betreuer[i].fldVornameB,
          fldeMailB: betreuer[i].fldeMailB,
          fkLehrbetrieb: betreuer[i].fkLehrbetrieb,
        };
      }
      this.betreuer = betreuer;
    });
    axios.get("/api/lehrbetriebe").then((response) => {
      this.lehrbetriebe = response.data;
    });
  },
  methods: {
    createBetreuer() {
      this.$refs.submitButton.setAttribute("disabled", true);
      axios.post("/api/betreuer", this.newBetreuer).then((response) => {
        if (response.status == 200) {
          let betreuer = response.data;
          betreuer.editable = false;
          betreuer.showDelete = false;
          betreuer.edit = {
            fldNachnameB: betreuer.fldNachnameB,
            fldVornameB: betreuer.fldVornameB,
            fldeMailB: betreuer.fldeMailB,
            fkLehrbetrieb: betreuer.fkLehrbetrieb,
          };
          this.betreuer.unshift(betreuer);
          this.showForm = false;
          for (const key in this.newBetreuer) {
            this.newBetreuer[key] = "";
          }
        }
      });
    },
    updateBetreuer(betreuer) {
      axios
        .post("/api/betreuer/" + betreuer.pkBetreuer, betreuer.edit)
        .then((response) => {
          if (response.status == 200) {
            let newBetreuer = response.data;
            newBetreuer.editable = false;
            newBetreuer.showDelete = false;
            newBetreuer.edit = {
              fldNachnameB: newBetreuer.fldNachnameB,
              fldVornameB: newBetreuer.fldVornameB,
              fldeMailB: newBetreuer.fldeMailB,
              fkLehrbetrieb: newBetreuer.fkLehrbetrieb,
            };
            this.betreuer.splice(
              this.betreuer.indexOf(betreuer),
              1,
              newBetreuer
            );
          }
        });
    },
    deleteBetreuer(betreuer) {
      axios.delete("/api/betreuer/" + betreuer.pkBetreuer).then((response) => {
        if (response.status == 200) {
          this.betreuer.splice(this.betreuer.indexOf(betreuer), 1);
        }
      });
    },
  },
};
</script>