<template>
  <div>
    <h1>Noten-Übersicht</h1>
    <!-- Filter -->
    <form
      class="
        row
        align-items-md-end
        pt-2
        pb-md-3
        sticky-md-top
        bg-light
        shadow-sm
        mb-4
        mx-md-0
      "
      @submit.prevent="
        filter();
        showLernende = true;
      "
    >
      <div class="col-12 mb-2 col-md col-xl-auto mb-md-0">
        <label for="nachnameFilter">Nachname</label>
        <input
          id="nachnameFilter"
          type="text"
          class="form-control"
          v-model="aktiverFilterNachname"
        />
      </div>
      <div class="col-12 mb-2 col-md col-xl-auto mb-md-0">
        <label for="vornameFilter">Vorname</label>
        <input
          id="vornameFilter"
          type="text"
          class="form-control"
          v-model="aktiverFilterVorname"
        />
      </div>
      <div class="col-12 mb-2 col-md col-xl-auto mb-md-0">
        <label for="lehrbetriebFilter">Firma</label>
        <input
          id="lehrbetriebFilter"
          type="text"
          class="form-control"
          v-model="aktiverFilterLehrbetrieb"
        />
      </div>
      <div class="col-12 mb-2 col-md-auto mb-md-0">
        <label for="BiVoFilter">BiVo</label>
        <select class="form-select" id="BiVoFilter" v-model="aktiverFilterBiVo">
          <option></option>
          <template v-for="filter in BiVoFilter">
            <option :key="filter" :value="filter">{{ filter }}</option>
          </template>
        </select>
      </div>
      <div class="col-12 mb-2 col-md-auto mb-md-0">
        <label for="lehrbeginnFilter">Lehrbeginn</label>
        <select
          class="form-select"
          id="lehrbeginnFilter"
          v-model="aktiverFilterLehrbeginn"
        >
          <option></option>
          <template v-for="filter in lehrbeginnFilter">
            <option :key="filter" :value="filter">{{ filter }}</option>
          </template>
        </select>
      </div>
      <div class="col-12 mb-2 col-md-auto mb-md-0">
        <button
          type="submit"
          class="
            btn btn-primary
            icon
            d-inline-flex
            align-items-center
            gap-1
            w-100
            justify-content-center
          "
          :disabled="!lernende.length"
        >
          <i class="bi bi-search"></i>Suchen
        </button>
      </div>
      <div
        class="col-12 mb-2 text-md-end mt-md-2 mb-md-0 col-xl-auto mt-xl-0"
        v-if="showLernende"
      >
        <button class="btn btn-link" @click="resetFilter()">
          zurücksetzen
        </button>
      </div>
    </form>
    <div class="text-end mb-3" v-if="showLernende && gefilterteLernende.length">
      <span class="badge rounded-circle bg-success p-1 text-dark">üK</span>
    </div>
    <div
      v-if="lernende.length && !gefilterteLernende.length && showLernende"
      class="alert alert-danger text-center mt-3"
    >
      Es wurden keine Ergebnisse gefunden.
    </div>
    <!-- Tabelle -->
    <template v-if="showLernende">
      <template v-for="bivo in BiVoFilter">
        <template v-for="lehrbeginn in lehrbeginnFilter">
          <div
            v-if="showTables.includes(bivo + lehrbeginn)"
            :key="bivo + lehrbeginn"
            class="table-responsive mb-3"
          >
            <table
              class="table table-sm text-nowrap table-bordered noten-uebersicht"
            >
              <thead>
                <tr>
                  <th>
                    <h2 class="fs-5">{{ bivo }} - {{ lehrbeginn }}</h2>
                  </th>
                  <template v-for="modul in module">
                    <th
                      :key="modul.pkModul"
                      v-if="modul.tables.includes(bivo + lehrbeginn)"
                      class="border position-relative"
                    >
                      <span
                        v-if="
                          gefilterteLernende
                            .find(
                              (lernender) =>
                                lernender.fldLehrbeginn == lehrbeginn &&
                                lernender.fldBiVo == bivo
                            )
                            .module.find(
                              (l_modul) => l_modul.pkModul == modul.pkModul
                            ).fldÜK
                        "
                        class="
                          badge
                          bg-success
                          p-1
                          rounded-circle
                          position-absolute
                          translate-middle-x
                          start-50
                          top-0
                          mt-2
                        "
                        ><span></span
                      ></span>
                      {{ modul.fldModulNr }}
                    </th>
                  </template>
                  <th class="border"><i class="bi bi-mortarboard"></i></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="lernender in gefilterteLernende">
                  <tr
                    :key="lernender.pkLernender"
                    v-if="
                      lernender.fldBiVo == bivo &&
                      lernender.fldLehrbeginn == lehrbeginn
                    "
                  >
                    <td>
                      {{ lernender.fldNachnameL }},
                      {{ lernender.fldVornameL }} /
                      {{ lernender.fldLehrbetrieb }}
                    </td>
                    <template v-for="modul in lernender.module">
                      <td :key="modul.pkModul">
                        {{ modul.note }}
                      </td>
                    </template>
                    <td>
                      <template v-if="lernender.durchschnitt">
                        <b>{{ lernender.durchschnitt }}</b>
                      </template>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      module: [],
      lernende: [],
      gefilterteLernende: [],
      modulnoten: [],
      lehrbeginnFilter: [],
      BiVoFilter: [],
      aktiverFilterVorname: "",
      aktiverFilterNachname: "",
      aktiverFilterLehrbetrieb: "",
      aktiverFilterBiVo: "",
      aktiverFilterLehrbeginn: "",
      showLernende: false,
    };
  },
  created() {
    axios.get("/api/module").then((response) => {
      this.module = response.data;
      this.module.sort((a, b) => {
        if (a.fldModulNr < b.fldModulNr) {
          return -1;
        } else if (a.fldModulNr > b.fldModulNr) {
          return 1;
        } else {
          return 0;
        }
      });
      axios.get("/api/modulbivo").then((response) => {
        let modulBiVo = response.data;
        for (let i = 0; i < this.module.length; i++) {
          const modul = this.module[i];
          let tables = [];
          for (let i = 0; i < modulBiVo.length; i++) {
            if (modulBiVo[i].fkModul == modul.pkModul) {
              if (
                !tables.includes(
                  modulBiVo[i].fldBiVo + modulBiVo[i].fldLehrbeginn
                )
              ) {
                tables.push(modulBiVo[i].fldBiVo + modulBiVo[i].fldLehrbeginn);
              }
            }
          }
          Vue.set(modul, "tables", tables);
        }
        axios.get("/api/modulnoten").then((response) => {
          this.modulnoten = response.data;
          this.modulnoten.sort((a, b) => {
            if (a.fldModulNr < b.fldModulNr) {
              return -1;
            } else if (a.fldModulNr > b.fldModulNr) {
              return 1;
            } else {
              return 0;
            }
          });
          axios.get("/api/lernende").then((response) => {
            let lernende = response.data;
            for (let i = 0; i < lernende.length; i++) {
              const lernender = lernende[i];
              if (!this.lehrbeginnFilter.includes(lernender.fldLehrbeginn)) {
                this.lehrbeginnFilter.push(lernender.fldLehrbeginn);
              }
              if (!this.BiVoFilter.includes(lernender.fldBiVo)) {
                this.BiVoFilter.push(lernender.fldBiVo);
              }
              this.lehrbeginnFilter.sort((a, b) => b - a);
              this.BiVoFilter.sort();
              let module = [];
              for (let i = 0; i < this.module.length; i++) {
                const modul = this.module[i];
                if (
                  modul.tables.includes(
                    lernender.fldBiVo + lernender.fldLehrbeginn
                  )
                ) {
                  let neuesModul = {
                    fldModulNr: modul.fldModulNr,
                    fldModulname: modul.fldModulname,
                    fldÜK: "",
                    pkModul: modul.pkModul,
                    note: "",
                    tables: modul.tables,
                  };
                  modulBiVo.forEach((modulBiVo) => {
                    if (
                      modulBiVo.fkModul == modul.pkModul &&
                      modulBiVo.fkBiVo == lernender.fkBiVo &&
                      modulBiVo.fldLehrbeginn == lernender.fldLehrbeginn
                    ) {
                      neuesModul.fldÜK = modulBiVo.fldÜK;
                    }
                  });
                  for (let i = 0; i < this.modulnoten.length; i++) {
                    const modulnote = this.modulnoten[i];
                    if (
                      modulnote.fkModul == neuesModul.pkModul &&
                      modulnote.fkLernender == lernender.pkLernender
                    ) {
                      neuesModul.note = modulnote.fldNote;
                    }
                  }
                  if (
                    neuesModul.note == 1 ||
                    neuesModul.note == 2 ||
                    neuesModul.note == 3 ||
                    neuesModul.note == 4 ||
                    neuesModul.note == 5 ||
                    neuesModul.note == 6
                  ) {
                    neuesModul.note += ".0";
                  }
                  module.push(neuesModul);
                }
              }
              // Berechnung Durchschnitte
              lernender.module = module;
              let ükDurchschnitt = 0;
              let anzÜkNoten = 0;
              let schulDurchschnitt = 0;
              let anzSchulNoten = 0;
              let durchschnitt = 0;
              for (let i = 0; i < lernender.module.length; i++) {
                const modul = lernender.module[i];
                let note = modul.note;
                if (Number(note)) {
                  note = Number(note);
                  if (modul.fldÜK == 1) {
                    ükDurchschnitt += note;
                    anzÜkNoten += 1;
                  }
                  if (modul.fldÜK == 0) {
                    schulDurchschnitt += note;
                    anzSchulNoten += 1;
                  }
                } else if (note !== "Dispens") {
                  modul.note = "";
                }
              }
              ükDurchschnitt =
                Math.round(
                  (Math.round((ükDurchschnitt / anzÜkNoten) * 10) / 10) * 2
                ) / 2;
              schulDurchschnitt =
                Math.round(
                  (Math.round((schulDurchschnitt / anzSchulNoten) * 10) / 10) *
                    2
                ) / 2;
              if (ükDurchschnitt && schulDurchschnitt) {
                durchschnitt =
                  Math.round(
                    ((ükDurchschnitt * 20 + schulDurchschnitt * 80) / 100) * 10
                  ) / 10;
              } else if (ükDurchschnitt) {
                durchschnitt = ükDurchschnitt;
              } else {
                durchschnitt = schulDurchschnitt;
              }
              if (
                durchschnitt == 1 ||
                durchschnitt == 2 ||
                durchschnitt == 3 ||
                durchschnitt == 4 ||
                durchschnitt == 5 ||
                durchschnitt == 6
              ) {
                durchschnitt += ".0";
              }
              lernender.durchschnitt = durchschnitt;
            }
            this.lernende = lernende;
          });
        });
      });
    });
  },
  methods: {
    filter() {
      let gefilterteLernende = this.lernende;
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldVornameL
          .toLowerCase()
          .includes(this.aktiverFilterVorname.toLowerCase());
      });
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldNachnameL
          .toLowerCase()
          .includes(this.aktiverFilterNachname.toLowerCase());
      });
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldLehrbetrieb
          .toLowerCase()
          .includes(this.aktiverFilterLehrbetrieb.toLowerCase());
      });
      if (this.aktiverFilterLehrbeginn !== "") {
        gefilterteLernende = gefilterteLernende.filter((lernender) => {
          return lernender.fldLehrbeginn == this.aktiverFilterLehrbeginn;
        });
      }
      gefilterteLernende = gefilterteLernende.filter((lernender) => {
        return lernender.fldBiVo.includes(this.aktiverFilterBiVo);
      });
      gefilterteLernende.sort((a, b) => {
        return a.fldNachnameL.localeCompare(b.fldNachnameL);
      });
      this.gefilterteLernende = gefilterteLernende;
    },
    resetFilter() {
      this.aktiverFilterVorname = "";
      this.aktiverFilterNachname = "";
      this.aktiverFilterLehrbetrieb = "";
      this.aktiverFilterBiVo = "";
      this.aktiverFilterLehrbeginn = "";
      this.filter();
      this.showLernende = false;
    },
  },
  computed: {
    showTables() {
      let showTables = [];
      for (let b = 0; b < this.BiVoFilter.length; b++) {
        const bivo = this.BiVoFilter[b];
        for (let l = 0; l < this.lehrbeginnFilter.length; l++) {
          const lehrbeginn = this.lehrbeginnFilter[l];
          if (
            this.gefilterteLernende.filter((lernender) => {
              return (
                lernender.fldBiVo == bivo &&
                lernender.fldLehrbeginn == lehrbeginn
              );
            }).length
          ) {
            showTables.push(bivo + lehrbeginn);
          }
        }
      }
      return showTables;
    },
  },
};
</script>