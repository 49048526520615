<template>
  <div>
    <h1>Lehrfirmen</h1>
    <form id="createLehrbetrieb" @submit.prevent="createLehrbetrieb()"></form>
    <div class="col-lg-6 table-responsive">
      <table class="table text-nowrap align-middle">
        <thead>
          <tr>
            <th>
              <button
                class="btn btn-primary icon"
                @click="showForm = true"
                title="neue Lehrfirma erfassen"
              >
                <i class="bi bi-plus"></i>
              </button>
            </th>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showForm" class="align-top">
            <td></td>
            <td>
              <input
                form="createLehrbetrieb"
                class="form-control"
                :class="{ 'is-invalid': errorName }"
                type="text"
                v-model="newLehrbetrieb.fldLehrbetrieb"
                required
              />
              <span
                class="invalid-feedback d-block"
                role="alert"
                v-if="errorName"
              >
                <strong>{{ errorName }}</strong>
              </span>
            </td>
            <td>
              <button
                type="submit"
                form="createLehrbetrieb"
                class="btn btn-success icon"
                title="Speichern"
                ref="submitButton"
              >
                <i class="bi bi-check"></i>
              </button>
              <button
                @click="
                  showForm = false;
                  errorName = false;
                  newLehrbetrieb.fldLehrbetrieb = '';
                "
                class="btn btn-danger icon"
                title="Abbrechen"
              >
                <i class="bi bi-x"></i>
              </button>
            </td>
          </tr>
          <template v-for="(lehrbetrieb, index) in lehrbetriebe">
            <tr
              :key="lehrbetrieb.pkLehrbetrieb"
              :class="{ 'align-top': lehrbetrieb.editable }"
            >
              <td>
                <button
                  class="btn btn-primary icon"
                  v-if="!lehrbetrieb.editable"
                  @click="lehrbetrieb.editable = true"
                  title="Bearbeiten"
                >
                  <i class="bi bi-pencil"></i>
                </button>
                <template v-if="lehrbetrieb.editable">
                  <form
                    :id="'updateLehrbetrieb' + index"
                    @submit.prevent="updateLehrbetrieb(lehrbetrieb)"
                  >
                    <button
                      type="submit"
                      class="btn btn-success icon"
                      title="Speichern"
                    >
                      <i class="bi bi-check"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger icon"
                      @click="
                        lehrbetrieb.editable = false;
                        lehrbetrieb.errorName = false;
                        lehrbetrieb.edit.fldLehrbetrieb =
                          lehrbetrieb.fldLehrbetrieb;
                      "
                      title="Abbrechen"
                    >
                      <i class="bi bi-x"></i>
                    </button>
                  </form>
                </template>
              </td>
              <td>
                <template v-if="!lehrbetrieb.editable">
                  {{ lehrbetrieb.fldLehrbetrieb }}
                </template>
                <input
                  type="text"
                  :form="'updateLehrbetrieb' + index"
                  class="form-control"
                  :class="{ 'is-invalid': lehrbetrieb.errorName }"
                  v-model="lehrbetrieb.edit.fldLehrbetrieb"
                  v-if="lehrbetrieb.editable"
                  required
                />
                <span
                  class="invalid-feedback d-block"
                  role="alert"
                  v-if="lehrbetrieb.errorName"
                >
                  <strong>{{ lehrbetrieb.errorName }}</strong>
                </span>
              </td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lehrbetriebe: [],
      showForm: false,
      errorName: false,
      newLehrbetrieb: {
        fldLehrbetrieb: "",
      },
    };
  },
  created() {
    axios.get("/api/lehrbetriebe").then((response) => {
      let lehrbetriebe = response.data;
      for (let i = 0; i < lehrbetriebe.length; i++) {
        const lehrbetrieb = lehrbetriebe[i];
        lehrbetrieb.editable = false;
        lehrbetrieb.errorName = false;
        lehrbetrieb.edit = {
          fldLehrbetrieb: lehrbetrieb.fldLehrbetrieb,
        };
      }
      this.lehrbetriebe = lehrbetriebe;
    });
  },
  methods: {
    createLehrbetrieb() {
      let sendable = true;
      if (
        this.lehrbetriebe.filter((lehrbetrieb) => {
          return (
            lehrbetrieb.fldLehrbetrieb.toLowerCase() ==
            this.newLehrbetrieb.fldLehrbetrieb.toLowerCase()
          );
        }).length
      ) {
        sendable = false;
        this.errorName = "Es gibt bereits eine Lehrfirma mit diesem Namen.";
      }
      if (sendable) {
        this.$refs.submitButton.setAttribute("disabled", true);
        axios
          .post("/api/lehrbetriebe", this.newLehrbetrieb)
          .then((response) => {
            if (response.status == 200) {
              let lehrbetrieb = response.data;
              lehrbetrieb.editable = false;
              lehrbetrieb.errorName = false;
              lehrbetrieb.edit = {
                fldLehrbetrieb: lehrbetrieb.fldLehrbetrieb,
              };
              this.lehrbetriebe.unshift(lehrbetrieb);
              this.showForm = false;
              this.newLehrbetrieb.fldLehrbetrieb = "";
            }
          });
        this.errorName = false;
      }
    },
    updateLehrbetrieb(lehrbetrieb) {
      let sendable = true;
      if (
        this.lehrbetriebe
          .filter((row) => {
            return row.pkLehrbetrieb !== lehrbetrieb.pkLehrbetrieb;
          })
          .filter((row) => {
            return (
              row.fldLehrbetrieb.toLowerCase() ==
              lehrbetrieb.edit.fldLehrbetrieb.toLowerCase()
            );
          }).length
      ) {
        sendable = false;
        this.lehrbetriebe[this.lehrbetriebe.indexOf(lehrbetrieb)].errorName =
          "Es gibt bereits eine Lehrfirma mit diesem Namen.";
      }
      if (sendable) {
        axios
          .post(
            "/api/lehrbetriebe/" + lehrbetrieb.pkLehrbetrieb,
            lehrbetrieb.edit
          )
          .then((response) => {
            if (response.status == 200) {
              let newLehrbetrieb = response.data;
              newLehrbetrieb.editable = false;
              newLehrbetrieb.errorName = false;
              newLehrbetrieb.edit = {
                fldLehrbetrieb: newLehrbetrieb.fldLehrbetrieb,
              };
              this.lehrbetriebe.splice(
                this.lehrbetriebe.indexOf(lehrbetrieb),
                1,
                newLehrbetrieb
              );
            }
          });
      }
    },
  },
};
</script>