/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

const { default: axios } = require('axios');

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 */


// Registrierung Vue-Komponenten
Vue.component('bivo', require('./components/BiVo.vue').default);
Vue.component('modul-erstellung', require('./components/Modul-Erstellung.vue').default);
Vue.component('modul-zuweisung', require('./components/Modul-Zuweisung.vue').default);
Vue.component('modul-uebersicht', require('./components/Modul-Uebersicht.vue').default);
Vue.component('firmen', require('./components/Firmen.vue').default);
Vue.component('betreuer', require('./components/Betreuer.vue').default);
Vue.component('lernende', require('./components/Lernende.vue').default);
Vue.component('noten-eingabe', require('./components/Noten-Eingabe.vue').default);
Vue.component('noten-uebersicht', require('./components/Noten-Uebersicht.vue').default);
Vue.component('noten-versand', require('./components/Noten-Versand.vue').default);
Vue.component('benutzer', require('./components/Benutzer.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
