<template>
  <div>
    <h1>Modul-Übersicht</h1>
    <!-- Filter -->
    <form
      class="
        row
        align-items-md-center
        pt-2
        pb-3
        sticky-md-top
        bg-light
        shadow-sm
        mb-2
        mx-md-0
      "
      @submit.prevent="filter()"
    >
      <div class="col row align-items-md-end gx-3 pe-0 ps-3">
        <div class="col-12 mb-2 col-md-auto mb-md-0">
          <label for="BiVoFilter">BiVo</label>
          <select
            class="form-select"
            id="BiVoFilter"
            v-model="aktiverFilterBiVo"
          >
            <option></option>
            <template v-for="filter in BiVoFilter">
              <option :key="filter" :value="filter">{{ filter }}</option>
            </template>
          </select>
        </div>
        <div class="col-12 mb-2 col-md-auto mb-md-0">
          <label for="lehrbeginnFilter">Lehrbeginn</label>
          <select
            class="form-select"
            id="lehrbeginnFilter"
            v-model="aktiverFilterLehrbeginn"
          >
            <option></option>
            <template v-for="filter in lehrbeginnFilter">
              <option :key="filter" :value="filter">{{ filter }}</option>
            </template>
          </select>
        </div>
        <div class="col-12 mb-2 col-md-auto mb-md-0">
          <button
            type="submit"
            class="
              btn btn-primary
              icon
              d-inline-flex
              align-items-center
              gap-1
              w-100
              justify-content-center
            "
          >
            <i class="bi bi-funnel"></i>Filtern
          </button>
        </div>
        <div
          class="col-12 mb-2 text-md-end mt-md-2 mb-md-0 col-md-auto mt-md-0"
          v-if="zuweisungen.length !== gefilterteZuweisungen.length"
        >
          <button class="btn btn-link" @click="resetFilter()">
            Filter zurücksetzen
          </button>
        </div>
      </div>
      <div class="d-flex flex-column mt-2 col-md-auto mt-md-0 ms-md-auto">
        <span class="fw-bold">{{ auswahl_zuweisungen.length }} ausgewählt</span>
        <button
          class="btn btn-danger col col-md-auto"
          :disabled="!auswahl_zuweisungen.length"
          @click="deleteZuweisungen()"
        >
          Zuweisungen löschen
        </button>
      </div>
    </form>
    <div
      v-if="zuweisungen.length && !gefilterteZuweisungen.length"
      class="alert alert-danger text-center mt-3"
    >
      Es wurden keine Ergebnisse gefunden.
    </div>
    <!-- Tabelle -->
    <div class="table-responsive mb-3" v-if="gefilterteZuweisungen.length">
      <table class="table table-hover text-nowrap align-middle">
        <thead>
          <tr>
            <th>
              <input
                class="form-check-input"
                type="checkbox"
                :checked="allChecked"
                @change="checkAll($event)"
              />
            </th>
            <th>Lehrbeginn</th>
            <th>BiVo</th>
            <th>Modul-Nr.</th>
            <th>Bezeichnung</th>
            <th>üK</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="zuweisung in gefilterteZuweisungen">
            <tr
              :key="zuweisung.pkModulBiVo"
              @click="toggleZuweisung(zuweisung)"
              style="cursor: pointer"
            >
              <td>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="auswahl_zuweisungen"
                  :value="zuweisung"
                />
              </td>
              <td>{{ zuweisung.fldLehrbeginn }}</td>
              <td>{{ zuweisung.fldBiVo }}</td>
              <td>{{ zuweisung.fldModulNr }}</td>
              <td>{{ zuweisung.fldModulname }}</td>
              <td>
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="zuweisung.fldÜK"
                  disabled
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zuweisungen: [],
      gefilterteZuweisungen: [],
      aktiverFilterLehrbeginn: "",
      aktiverFilterBiVo: "",
      lehrbeginnFilter: [],
      BiVoFilter: [],
      auswahl_zuweisungen: [],
    };
  },
  created() {
    axios.get("/api/modulbivo").then((response) => {
      this.zuweisungen = response.data;
      for (let i = 0; i < this.zuweisungen.length; i++) {
        const zuweisung = this.zuweisungen[i];
        if (!this.lehrbeginnFilter.includes(zuweisung.fldLehrbeginn)) {
          this.lehrbeginnFilter.push(zuweisung.fldLehrbeginn);
        }
        if (!this.BiVoFilter.includes(zuweisung.fldBiVo)) {
          this.BiVoFilter.push(zuweisung.fldBiVo);
        }
        this.lehrbeginnFilter.sort((a, b) => b - a);
        this.BiVoFilter.sort();
      }
      this.filter();
    });
  },
  methods: {
    filter() {
      let gefilterteZuweisungen = this.zuweisungen;
      if (this.aktiverFilterLehrbeginn !== "") {
        gefilterteZuweisungen = gefilterteZuweisungen.filter((zuweisung) => {
          return zuweisung.fldLehrbeginn == this.aktiverFilterLehrbeginn;
        });
      }
      gefilterteZuweisungen = gefilterteZuweisungen.filter((zuweisung) => {
        return zuweisung.fldBiVo.includes(this.aktiverFilterBiVo);
      });
      gefilterteZuweisungen.sort((a, b) => {
        return a.fldModulNr.localeCompare(b.fldModulNr, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
      this.gefilterteZuweisungen = gefilterteZuweisungen;
    },
    resetFilter() {
      this.aktiverFilterBiVo = "";
      this.aktiverFilterLehrbeginn = "";
      this.filter();
    },
    deleteZuweisungen() {
      if (
        confirm(
          "Wollen Sie diese Zuweisungen wirklich löschen?\nAlle Noten dieser Zuweisung werden ebenfalls gelöscht!"
        )
      ) {
        let data = {
          zuweisungen: this.auswahl_zuweisungen,
        };
        axios.post("/api/modulbivo/delete", data);
        for (let i = 0; i < this.auswahl_zuweisungen.length; i++) {
          const zuweisung = this.auswahl_zuweisungen[i];
          this.zuweisungen.splice(this.zuweisungen.indexOf(zuweisung), 1);
          this.gefilterteZuweisungen.splice(
            this.gefilterteZuweisungen.indexOf(zuweisung),
            1
          );
        }
        this.auswahl_zuweisungen = [];
      }
    },
    toggleZuweisung(zuweisung) {
      if (this.auswahl_zuweisungen.includes(zuweisung)) {
        this.auswahl_zuweisungen.splice(
          this.auswahl_zuweisungen.indexOf(zuweisung),
          1
        );
      } else {
        this.auswahl_zuweisungen.push(zuweisung);
      }
    },
    checkAll(event) {
      for (let i = 0; i < this.gefilterteZuweisungen.length; i++) {
        const zuweisung = this.gefilterteZuweisungen[i];
        let found = false;
        if (event.target.checked) {
          for (let i = 0; i < this.auswahl_zuweisungen.length; i++) {
            const checkedZuweisung = this.auswahl_zuweisungen[i];
            if (checkedZuweisung.pkModulBiVo == zuweisung.pkModulBiVo) {
              found = true;
              break;
            }
          }
          if (found == false) {
            this.auswahl_zuweisungen.push(zuweisung);
          }
        } else {
          for (let i = 0; i < this.auswahl_zuweisungen.length; i++) {
            const checkedZuweisung = this.auswahl_zuweisungen[i];
            if (checkedZuweisung.pkModulBiVo == zuweisung.pkModulBiVo) {
              this.auswahl_zuweisungen.splice(i, 1);
            }
          }
        }
      }
    },
  },
  computed: {
    allChecked() {
      if (!this.gefilterteZuweisungen.length) {
        return false;
      }
      let allFound = true;
      for (let i = 0; i < this.gefilterteZuweisungen.length; i++) {
        const zuweisung = this.gefilterteZuweisungen[i];
        if (
          !this.auswahl_zuweisungen.some(
            (checkedZuweisung) =>
              checkedZuweisung.pkModulBiVo == zuweisung.pkModulBiVo
          )
        ) {
          allFound = false;
        }
      }
      if (allFound) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>